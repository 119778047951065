import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageMenuConfRegister
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuConfRegister( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Menu' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Registrations List"                 {...Th.menuBtn}   {...stl}  show={testRegisList()}                 onTap={onTapRegisList}  />
                <FtButton label="Registration Cards"                 {...Th.menuBtn}   {...stl}  show={testRegisCards()}                onTap={onTapRegisCards}  />
                <FtButton label="Registration Cards Extra"           {...Th.menuBtn}   {...stl}  show={testRegisCardsExtra()}           onTap={onTapRegisCardsExtra}  />
                <FtButton label="Attendee Statistics"                {...Th.menuBtn}   {...stl}  show={testAttendeeStats()}             onTap={onTapAttendeeStats}  />
                <FtButton label="Report: Quorum Detail"              {...Th.menuBtn}   {...stl}  show={testReportQuorumDetail()}        onTap={onTapReportQuorumDetail}  />
                <FtButton label="Report: Quorum Totals"              {...Th.menuBtn}   {...stl}  show={testReportQuorumTotals()}        onTap={onTapReportQuorumTotals}  />
                <FtButton label="Report: Summary Total Attendees"    {...Th.menuBtn}   {...stl}  show={testReportSummaryTotalAtt()}     onTap={onTapReportSummaryTotalAtt}  />
                <FtButton label="Report: Attendees List"             {...Th.menuBtn}   {...stl}  show={testReportAttList()}             onTap={onTapReportAttList}  />
                <FtButton label="Report: Attendees by Region"        {...Th.menuBtn}   {...stl}  show={testReportAttbyRegion()}         onTap={onTapReportAttbyRegion}  />
                <FtButton label="Report: Attendees by Type"          {...Th.menuBtn}   {...stl}  show={testReportAttbyType()}           onTap={onTapReportAttbyType}  />
                <FtButton label="Report: Attendees by Region No ID"  {...Th.menuBtn}   {...stl}  show={testReportAttbyRegionNoID()}     onTap={onTapReportAttbyRegionNoID}  />
                <FtButton label="Report: Attendee Summary"           {...Th.menuBtn}   {...stl}  show={testReportAttSummary()}          onTap={onTapReportAttSummary}  />
                <FtSpacer />
                <FtButton label="Back to Main Menu"                 {...Th.menuBtn}   {...stl}  onTap={nav.pop} />                
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function testRegisList(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapRegisList(): void
    {
        nav.push( 'AttendeeSearch' );
    }
    
    function testRegisCards(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapRegisCards(): void
    {
        nav.push( 'ConfregisCards' );
    }
    
    function testRegisCardsExtra(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapRegisCardsExtra(): void
    {
        nav.push( 'ConfregisCardsExtra' );
    }
    
    function testAttendeeStats(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_DISPLAY_STATS );
    }
    
    function onTapAttendeeStats(): void
    {
        nav.push( 'ConfregisStatistics' );
    }
    
    function testReportQuorumDetail(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportQuorumDetail(): void
    {
        nav.push( 'ReportConfregisQuorumDetail' );
    }
    
    function testReportQuorumTotals(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportQuorumTotals(): void
    {
        nav.push( 'ReportConfregisQuorumTotal' );
    }
    
    function testReportSummaryTotalAtt(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportSummaryTotalAtt(): void
    {
        nav.push( 'ReportConfregisSummaryTotal' );
    }
    
    function testReportAttList(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportAttList(): void
    {
        nav.push( 'ReportConfregisAttendees' );
    }
    
    function testReportAttbyRegion(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportAttbyRegion(): void
    {
        nav.push( 'ReportConfregisByRegion' );
    }
    
    function testReportAttbyType(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportAttbyType(): void
    {
        nav.push( 'ReportConfregisByType' );
    }
    
    function testReportAttbyRegionNoID(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportAttbyRegionNoID(): void
    {
        nav.push( 'ReportConfregisByRegionNoId' );
    }
    
    function testReportAttSummary(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }
    
    function onTapReportAttSummary(): void
    {
        nav.push( 'ReportConfregisSummary' );
    }
    
}
