import VotingRoundBase from './base/votingRoundBase';

//********************************************************************
// VotingRound class
// @FTGEN_PROGRESS: busy
export default class VotingRound extends VotingRoundBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
