import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtText from '../first/ftText';
import FtSpacer from '../first/ftSpacer';
import Th from '../general/th';

import VotingPosition  from '../models/votingPosition';
import VotingRound     from '../models/votingRound';
import VotingCandidate from '../models/votingCandidate';
import RoundStatus     from '../models/roundStatus';
import GenLookup       from '../models/genLookup';

const MSG_STATE_NONE      = 0;  // no position or round open                              Welcome to the AFM of SA GBM Conference
const MSG_STATE_POS_OPEN  = 1;  // position open and no rounds open                       Voting for <position> to start soon
const MSG_STATE_RND_OPEN  = 2;  // position and round open                                Voting for <position> in progress / <round-name> / Nominations/Candidates: <noms-list>
const MSG_STATE_RND_PROC  = 3;  // position open and last round in correct or finalize    Voting for <position> in progress / <round-name> being processed
const MSG_STATE_RND_COMPL = 4;  // position open and last round completed                 Voting for <position> in progress / <round-name> completed

//********************************************************************
// PageVoteDisplayStatus
// @FTGEN_PROGRESS: busy
// ...todo: specify props here...
export default function PageVoteDisplayStatus( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ mdlPosition  , setMdlPosition  ] = useState<VotingPosition|null>( null );
    const [ mdlRound     , setMdlRound     ] = useState<VotingRound|null>( null );
    const [ lkpCandidate , setLkpCandidate ] = useState<GenLookup[]>( [] );
    const [ lstFinalist  , setLstFinalist  ] = useState<VotingCandidate[]>( [] );
    const duration = 5000;    

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    //- calculate status -----
    const msgState = getMsgState( mdlPosition, mdlRound );

    //- styles -----
    const txtGbm: any = {
        ss: '12',
        lt: 'C,A',
        txtDescr: '80,#c04040',
    };
    const txtProgInd: any = {
        ss: '12',
        lt: 'C,A',
        txtDescr: '90,#0e71b0',
    };    
    const txtReady: any = {
        ss: '12',
        lt: 'C,A',
        txtDescr: '70,#0e71b0',
    };        

    //- render  -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={`Voting Status [ ${Ft.dtmFmt()} ]`} {...Th.frame} md='c' lt='C,A'>

            {/*=== No State =============================================*/}
            {(msgState != MSG_STATE_NONE ? (<></>) : (<>
                <FtText {...txtGbm}      text='AFM GBM 2024' />
                <FtText {...txtProgInd}  text='Voting Process Indicator' />
            </>))}

            {/*=== Open Position Empty rounds =============================================*/}
            {(msgState != MSG_STATE_POS_OPEN ? (<></>) : (<>
                <FtText ss='12' lt='C,A' txtDescr='60,#c04040' text={`Voting for ${mdlPosition?.name} to start soon...`} />
                <FtText ss='12' lt='C,A' txtDescr='80,#0e71b0' text='Please get ready...' />
            </>))}

            {/*=== Open Position and round open =============================================*/}
            {(msgState != MSG_STATE_RND_OPEN ? (<></>) : (<>
                <FtCon ss='12' md='c' lt='C,A'>
                    <FtText ss='12' lt='C,A' txtDescr='60,#c04040' text={`Voting for ${mdlPosition?.name} in progress.`} />
                    <FtText ss='12' lt='C,A' txtDescr='80,#c04040' text={mdlRound?.round_name ?? ''} />
                    <FtText ss='12' lt='C,A' txtDescr='60,#000000' text={mdlRound?.round_no != 0 ? 'CANDIDATES' : ''} />
                    <FtCon ss='12' h={3} bkgClr="#a0a0a0" />
                    <FtCon ss='12' md='rw'>
                        {lkpCandidate.map( can => (<FtText key={Ft.key()} ss='6' txtDescr='45,#000000' text={can.name!} />) )}
                    </FtCon>
                </FtCon>
            </>))}

            {/*=== Open Position and round in correction/finalization ========================*/}
            {(msgState != MSG_STATE_RND_PROC ? (<></>) : (<>
                <FtText ss='12' lt='C,A' txtDescr='60,#c04040' text={`Voting for ${mdlPosition?.name} in progress.`} />
                <FtText ss='12' lt='C,A' txtDescr='60,#0e71b0' text={`${mdlRound?.round_name ?? ''} is being processed.`} />
            </>))}

            {/*=== Open Position and round completed ========================================*/}
            {(msgState != MSG_STATE_RND_COMPL ? (<></>) : (<>
                <FtText ss='12' lt='C,A' txtDescr='60,#c04040' text={`Voting for ${mdlPosition?.name} in progress.`} />
                <FtText ss='12' lt='C,A' txtDescr='60,#0e71b0' text={`${mdlRound?.round_name ?? ''} has completed.`} />
                <FtText ss='12' lt='C,A' txtDescr='60,#000000' text={(mdlRound?.round_no ?? 0) > 0 ? 'FINALISTS' : 'NOMINATIONS'} />
                    <FtCon ss='12' h={3} bkgClr="#a0a0a0" />
                    <FtCon ss='12' md='rw' lt='a,a'>
                        {lstFinalist.map( fin => (<>
                            <FtText key={Ft.key()} ss='12' txtDescr='35,#000000' text={`${fin.name!} (${fin!.vote_perc!.toFixed(2)} %)`} />
                            <FtSpacer h={0} />
                            <FtText key={Ft.key()} ss='12' txtDescr='25,#0e71b0' text={`   - Region: ${fin!.region_name}   ~   Assembly: ${fin!.assembly_name}`} />
                        </>) )}
                    </FtCon>                
            </>))}

        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        loadData();
    }

    function loadData(): void
    {
        FtRapi.callList( 'VoteStatusSearch', {
            success: ( result, data ) => {
                setMdlPosition( data[0] );
                setMdlRound( data[1] );
                setLkpCandidate( data[2] );
                setLstFinalist( data[3] );
            },
            // don't display errors, just let it revert to welcome message
        });
        setTimeout( loadData, duration );
    }

    // Get current message state
    // voting_position [openPos]   Currently open position or null
    // voting_round    [lastRound] Last round in currently open position or null
    function getMsgState( pos: VotingPosition|null, round: VotingRound|null ): number
    {
        if ( pos == null )
            return MSG_STATE_NONE;        // no position or round open                              Welcome to the AFM of SA GBM Conference
        if ( round == null || round.round_no == 0 && round.status_id == RoundStatus.RS_CREATED )
            return MSG_STATE_POS_OPEN;    // position open and no rounds open                       Voting for <position> to start soon
        if ( round.status_id == RoundStatus.RS_OPEN_FOR_VOTING )
            return MSG_STATE_RND_OPEN;    // position and round open                                Voting for <position> in progress / <round-name> / Nominations/Candidates: <noms-list>
        if ( round.status_id == RoundStatus.RS_PROCESSING || round.status_id == RoundStatus.RS_APPROVED_FINAL )
            return MSG_STATE_RND_PROC;    // position open and last round in processing             Voting for <position> in progress / <round-name> being finalized
        if ( round.status_id == RoundStatus.RS_COMPLETED )
            return MSG_STATE_RND_COMPL;   // position open and last round completed                 Voting for <position> in progress / <round-name> completed
        return MSG_STATE_NONE;            // no position or round open                              Welcome to the AFM of SA GBM Conference
    }
}
