import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import VotingPosition  from '../models/votingPosition';
import VotingRound     from '../models/votingRound';
import VotingCandidate from '../models/votingCandidate';
import CandidateStatus from '../models/candidateStatus';

//********************************************************************
// PageVoteApprovalPrimary
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteApprovalPrimary( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup           , setPopup           ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy            , setBusy            ] = useState( false );
    const [ mdlPosition     , setMdlPosition     ] = useState<VotingPosition|null>( null );
    const [ mdlRound        , setMdlRound        ] = useState<VotingRound|null>( null );
    const [ mdlCandidate    , setMdlCandidate    ] = useState<VotingCandidate>( new VotingCandidate() );
    const [ lstCandidate    , setLstCandidate    ] = useState<VotingCandidate[]>( [] );
    const [ showEdit        , setShowEdit        ] = useState<boolean>( false );
    const [ formKey         , setFormKey         ] = useState<string>( Ft.key() ); // used to force rerender of form

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlPosition, mdlRound ) ) return ftBusy( { popup: popup } );

    /*
    PREMISE:
    this function approves or rectifies votes on existing candidates
    candidates can only be deleted during nomination round
    approving hides buttons
    any updates reloads whole screen

    DESIGN:
    === Current Status ===
    Open Position : None/<name>
    Open Round : None/<name>
    ======================

    === Edit Candidate ===========
    Name      : <fullname>
    Votes     : <int-input>
    ==============================

    === Approve Candidates (Primary) ===
     CANDIDATE       VOTES          ACTIONS
     <name>          <votes>        [Edit] [Approve] [Delete]
    =============================
    */

    const txtStatus: any = {
        ss: '12',
        txtDescr: '20,WCO#273891',
    };
    const txtHeading: any = {
        txtDescr: '20,WO#005493',
    };
    const txtItem: any = {
        txtDescr: '18,WO#000000'
    };
    const btnProps: any = {
        clr     : "#008000",
        clrX    : "#cccccc",
        txtClr  : "#ffffff",
        txtClrX : "#000000",
        pad: 3
    };
    const roundName = mdlRound?.round_no == 0 ? 'Nomination' : `${mdlRound?.round_no}`;

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Current Voting Status' {...Th.frame}>
            <FtText {...txtStatus} text={`Open Position: ${mdlPosition?.name}`} />
            <FtText {...txtStatus} text={`Open Round : ${roundName}`} />
        </FtFrame>

        <FtFrame text='Edit Candidate Votes' {...Th.frame} show={showEdit}>
            <FtForm {...Th.form}
                key={formKey}
                models={{ m: mdlCandidate }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.name'        label='Name'  {...Th.field} en={0} ss='9' />
                <FtFmIntegerField  name='m.vote_count'  label='Votes' {...Th.field} />
                <FtSpacer />
                <FtCon ss='12' md='r' lt='c,a'>
                    <FtButton   ss='0:2:1' label='Cancel Edit'  {...Th.button} onTap={onEditCancel}  />
                    <FtFmSubmit ss='1:2' label='Save Votes'   {...Th.button} />
                </FtCon>
            </FtForm>
        </FtFrame>

        <FtFrame text='Approve Candidate Votes' {...Th.frame} show={!showEdit} md='rw'>
            <FtText ss='6' {...txtHeading} text='Candidate' />
            <FtText ss='2' {...txtHeading} text='Votes' />
            <FtText ss='4' {...txtHeading} text='Actions' />

            {lstCandidate.map( can => (<>
                <FtText ss='6' {...txtItem} text={can.name} />
                <FtText ss='2' {...txtItem} text={can.vote_count} />
                <FtCon key={Ft.key()} ss='4' md='r' show={showButtons( can )}>
                    <FtButton key={Ft.key()} label="Edit"    {...btnProps} onTap={() => candidateEdit( can )}   />
                    <FtButton key={Ft.key()} label="Approve" {...btnProps} onTap={() => candidateApprove( can )}    />
                    <FtButton key={Ft.key()} label="Delete"  {...btnProps} onTap={() => candidateDelete( can )} show={mdlRound?.round_no === 0} />
                </FtCon>
                <FtText ss='4' {...txtItem} text='Approved' show={!showButtons( can )} />
                <FtSpacer h={10} show={!showButtons( can )} />
            </>))}
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        loadData();
    }

    function loadData()
    {
        setBusy( true );
        FtRapi.callList( 'VoteApprovalInfo', {
            success: ( result, data ) => {
                setMdlPosition( data[0] );
                setMdlRound( data[1] );
                setLstCandidate( data[2] );
                setShowEdit( false );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onSave(): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to save the new votes?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VotePrimaryRevote', mdlCandidate.id, {
                urlParms: { vote_count: mdlCandidate.vote_count },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function showButtons( can: VotingCandidate ): boolean
    {
        return can.status_id == CandidateStatus.CS_OPEN_FOR_VOTING;
    }

    function candidateEdit( can: VotingCandidate ): void
    {
        const can2: VotingCandidate = new VotingCandidate();
        can2.id         = can.id;
        can2.name       = can.name;
        can2.vote_count = can.vote_count;
        setMdlCandidate( can2 );
        setFormKey( Ft.key() );
        setShowEdit( true );
    }

    function onEditCancel(): void
    {
        setShowEdit( false );
    }

    function candidateApprove( can: VotingCandidate ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to approve this candidate?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VotePrimaryApprove', can.id, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function candidateDelete( can: VotingCandidate ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to delete this candidate?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VotePrimaryDelete', can.id, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            })
        } } );
    }
}
