import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup         from '../models/genLookup';

const TYPE_VIP: number = 1;
const TYPE_VOLUNTEER: number = 2;

//********************************************************************
// PageConfregisCardsExtra
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageConfregisCardsExtra( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup         ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy          ] = useState( false );
    const [ formKey       , setFormKey       ] = useState<string>( Ft.randomStr() );
    const [ lkpType       , setLkpType       ] = useState<GenLookup[]|null>( null );
    const [ mdlInfo       , setMdlInfo       ] = useState<any>( { type_id: 1, names: '' } );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlInfo, lkpType ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Card Parameters' {...Th.frame}>
            <FtForm 
                {...Th.form}
                key={formKey}
                models={{ m: mdlInfo }}
                onSubmit={onGenerate}
            >
                <FtFmDropdownField  name='m.type_id' label='Type of Card'          {...Th.field} ftreq data={lkpType} />
                <FtSpacer h={0} />
                <FtFmTextareaField  name='m.names'   label='Names (one per line)'  {...Th.field} ss='6' lines={15} />
                <FtSpacer />
                <FtFmSubmit label='Generate Cards' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setLkpType( [
            ( new GenLookup() ).copyFrom( { id: TYPE_VIP       , name: 'VIP' } ),
            ( new GenLookup() ).copyFrom( { id: TYPE_VOLUNTEER , name: 'Volunteer' } ),
        ]);
    }

    function onGenerate(): void
    {
        Ut.openReport( 'reportConfregisCardsExtra', {
            type_id : mdlInfo!.type_id,
            names   : mdlInfo!.names,
        } );
    }
}
