import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtCon from '../first/ftCon';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmEmailField from '../first/ftFmEmailField';
import FtFmDecimalField from '../first/ftFmDecimalField';
import FtFmDateField from '../first/ftFmDateField';
import FtFmTimeField from '../first/ftFmTimeField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmDropdownField from '../first/ftFmDropdownField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import Attendee      from '../models/attendee';
import GenLookup     from '../models/genLookup';

//********************************************************************
// PageAttendeeEdit
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageAttendeeEdit( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const { attendeeId } = nav.params ?? { attendeeId: 0 };    // unpack parameters passed from calling page
    const [ popup         , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy         ] = useState( false );
    const [ mdlAttendee   , setMdlAttendee  ] = useState<Attendee|null>( null );
    const [ lkpAssembly   , setLkpAssembly  ] = useState<GenLookup[]>( [] );
    const [ lkpRegion     , setLkpRegion    ] = useState<GenLookup[]>( [] );
    const [ lkpUserz      , setLkpUserz     ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlAttendee, lkpAssembly, lkpRegion, Ut.lkpAttendeeTitle, lkpUserz ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text={(attendeeId == 0 ? 'New' : 'Edit') + ' Attendee'} {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlAttendee }}
            >
                <FtFmTextField     name='m.rsaid_no'               label='ID Number'             {...Th.field} ftreq />
                <FtFmTextField     name='m.postal_adr_1'           label='Postal Address'        {...Th.field} ftreq />

                <FtFmDropdownField name='m.title_id'               label='Title'                 {...Th.field} ftreq data={Ut.lkpAttendeeTitle} />
                <FtFmTextField     name='m.postal_adr_2'           label=''                      {...Th.field} />

                <FtFmTextField     name='m.fullnames'              label='Fullnames'             {...Th.field} ftreq />
                <FtFmTextField     name='m.postal_adr_code'        label='Postal Code'           {...Th.field} ftreq />

                <FtFmTextField     name='m.surname'                label='Surname'               {...Th.field} ftreq />
                <FtFmDropdownField name='m.province_id'            label='Province'              {...Th.field} ftreq data={Ut.lkpProvince} />

                <FtFmTextField     name='m.cell_no'                label='Cell No'               {...Th.field} ftreq />
                <FtFmSearchField   name='m.region_id'              label='Region'                {...Th.field} ftreq data={lkpRegion} />

                <FtFmEmailField    name='m.email'                  label='Email'                 {...Th.field} ftreq />
                <FtFmSearchField   name='m.assembly_id'            label='Assembly'              {...Th.field} ftreq data={lkpAssembly} />

                <FtFmDropdownField name='m.attendee_type_id'       label='Attendee Type'         {...Th.field} ftreq data={Ut.lkpAttendeeType} />
                <FtFmDropdownField name='m.reg_status_id'          label='Reg Status'            {...Th.field} data={Ut.lkpRegisterStatus} />

                <FtFmDateField     name='m.capture_dttm'           label='Capture Date'          {...Th.field} />
                <FtSpacer />

                <FtFmDropdownField name='m.payment_method_id'      label='Payment Method'        {...Th.field} data={Ut.lkpPayMethod} />
                <FtFmCheckboxField name='m.goodybag_received'      label='Goody-Bag Received'    {...Th.field} />

                <FtFmDateField     name='m.payment_date'           label='Payment Date'          {...Th.field} />
                <FtFmDropdownField name='m.goodybag_scan_user_id'  label='Goody-Bag Scan User'   {...Th.field} data={lkpUserz} en={0} />

                <FtFmCheckboxField name='m.payment_online'         label='Payment Online'        {...Th.field} />
                <FtFmDateField     name='m.goodybag_scan_dttm'     label='Goody-Bag Scan Date'   {...Th.field} en={0} />

                <FtFmDecimalField  name='m.payment_amount'         label='Payment Amount'        {...Th.field} />
                <FtFmTimeField     name='m.goodybag_scan_dttm'     label='Goody-Bag Scan Time'   {...Th.field} en={0} />

                <FtSpacer />
                <FtCon ss='12' md='r' lt='C,A'>
                    <FtFmSubmit label='Save Only'            {...Th.submit2} onSubmit={() => onSave( false ) } />
                    <FtFmSubmit label='Save and Send Letter' {...Th.submit2} onSubmit={() => onSave( true ) } />
                </FtCon>
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        Ut.loadLookups( {
            success: ( result, data ) => setBusy( false ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        FtRapi.callList( 'userzLookup', {
            success: ( result, data ) => setLkpUserz( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
        });

        //- init record -----
        if ( attendeeId != 0 ) {  // existing record
            FtRapi.callRead( 'attendeeRead', attendeeId, {
                success: ( result, data ) => setMdlAttendee( data[0] ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            });
        } else {             // new record
            setMdlAttendee( new Attendee() );
        }
    }

    function onSave( withLetter: boolean ): void
    {
        setBusy( true );
        if ( attendeeId != 0 ) {  // existing record
            FtRapi.callUpdate( 'attendeeUpdate', attendeeId, {
                urlParms: { withLetter: withLetter ? 1 : 0 },
                inRec: mdlAttendee,
                success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        } else {             // new record
            FtRapi.callNew( 'attendeeNew', {
                urlParms: { withLetter: withLetter ? 1 : 0 },
                inRec: mdlAttendee,
                success: ( { message }, data ) => {
                    ftPopupMessage( setPopup, "Success", message );
                    setMdlAttendee( new Attendee() );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                complete: () => setBusy( false ),
            });
        }
    }

}
