import VotingCandidateBase from './base/votingCandidateBase';

//********************************************************************
// VotingCandidate class
// @FTGEN_PROGRESS: busy
export default class VotingCandidate extends VotingCandidateBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
