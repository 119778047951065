import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup       from '../models/genLookup';
import VotingCandidate from '../models/votingCandidate';

//********************************************************************
// PageReportVoteResults
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageReportVoteResults( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ lkpPosRound  , setLkpPosRound  ] = useState<GenLookup[]>( [] );
    const [ mdlCandidate , setMdlCandidate ] = useState<VotingCandidate>( new VotingCandidate() );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( lkpPosRound ) ) return ftBusy( { popup: popup } );

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Report Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlCandidate }}
                onSubmit={onGenerate}
            >
                <FtFmSearchField name='m.round_id'  label='Position : Round' {...Th.field} ss='9' data={lkpPosRound} />
                <FtSpacer />
                <FtFmSubmit label='Generate Report' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        setBusy( true );
        FtRapi.callList( 'VotePosRoundLookup', {
            success: (result, data) => setLkpPosRound( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onGenerate(): void
    {
        Ut.openReport( 'reportVoteResults', { round_id: mdlCandidate.round_id } );
    }

}
