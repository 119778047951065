import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmCheckboxField from '../first/ftFmCheckboxField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupOptions } from '../first/ftPopupOptions';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup         from '../models/genLookup';
import Attendee          from '../models/attendee';

//********************************************************************
// PageConfregisCards
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageConfregisCards( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup         ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy          ] = useState( false );
    const [ formKey       , setFormKey       ] = useState<string>( Ft.randomStr() );
    const [ mdlAttendee   , setMdlAttendee   ] = useState<Attendee>( new Attendee() );
    const [ lkpAssembly   , setLkpAssembly   ] = useState<GenLookup[]>( [] );
    const [ lkpRegion     , setLkpRegion     ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlAttendee, lkpAssembly, lkpRegion ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Card Parameters' {...Th.frame}>
            <FtForm 
                {...Th.form}
                key={formKey}
                models={{ m: mdlAttendee }}
                onSubmit={onGenerate}
            >
                <FtFmTextField     name='m.surname'        label='Surname'        {...Th.field} />
                <FtFmTextField     name='m.rsaid_no'       label='ID Number'      {...Th.field} />
                <FtFmSearchField   name='m.assembly_id'    label='Assembly'       {...Th.field} data={lkpAssembly} />
                <FtFmSearchField   name='m.region_id'      label='Region'         {...Th.field} data={lkpRegion} />
                <FtFmCheckboxField name='m.card_printed'   label='Printed'        {...Th.field} />
                <FtSpacer />
                <FtFmSubmit label='Generate Cards' {...Th.submit} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setBusy( true );
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion( data[1] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onGenerate(): void
    {
        Ut.openReport( 'reportConfregisCards', {
            surname      : mdlAttendee!.surname,
            rsaid_no     : mdlAttendee!.rsaid_no,
            assembly_id  : mdlAttendee!.assembly_id,
            region_id    : mdlAttendee!.region_id,
            card_printed : mdlAttendee!.card_printed ? 1 : 0
        } );
        ftPopupOptions( setPopup, "Confirm", "Did the cards Print Correctly", { onA: () => markAsPrinted(), btnA: "Yes", btnB: "No" });
    }

    function markAsPrinted(): void
    {
        setBusy( true );
        FtRapi.callNew( 'ConfregisCardsMark', {
            success: ( { message }, data ) => {
                setMdlAttendee( new Attendee() );
                setFormKey( Ft.randomStr() );
                ftPopupMessage( setPopup, "Success", message, { btn: "Close", onClose: nav.pop } );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }
}
