import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmIntegerField from '../first/ftFmIntegerField';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import GenLookup       from '../models/genLookup';
import VotingPosition  from '../models/votingPosition';
import VotingRound     from '../models/votingRound';
import VotingCandidate from '../models/votingCandidate';

//********************************************************************
// PageVoteCaptureNomin
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteCaptureNomin( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here
    
    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup           , setPopup           ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy            , setBusy            ] = useState( false );
    const [ mdlPosition     , setMdlPosition     ] = useState<VotingPosition|null>( null );
    const [ mdlRound        , setMdlRound        ] = useState<VotingRound|null>( null );
    const [ mdlCandidate    , setMdlCandidate    ] = useState<VotingCandidate>( new VotingCandidate() );
    const [ lkpCandidate    , setLkpCandidate    ] = useState<GenLookup[]|null>( null );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render
    
    if ( busy || Ft.eAny( mdlPosition, mdlRound, lkpCandidate ) ) return ftBusy( { popup: popup } );

    /*
    DESIGN:
    === Current Status ===
    Open Position : None/<name>
    Open Round : None/<name>
    ======================

    === Capture New Candidate ===
     Candidate: <text-input>
     Votes: <int-input>
    =============================
    */

    const txtStatus: any = {
        ss: '12',
        txtDescr: '20,WCO#273891',
    };

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Current Voting Status' {...Th.frame}>
            <FtText {...txtStatus} text={`Open Position: ${mdlPosition?.name}`} />
            <FtText {...txtStatus} text='Open Round : Nomination' />
        </FtFrame>

        <FtFrame text='Capture New Candidate' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlCandidate }}
                onSubmit={onSave}
            >
                <FtFmSearchField   name='m.attendee_id'  label='Attendee'  {...Th.field} ss='9' data={lkpCandidate} />
                <FtFmIntegerField  name='m.vote_count'   label='Votes'     {...Th.field} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Save Candidate' {...Th.button} />
            </FtForm>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void 
    {
        loadData();
    }

    function loadData()
    {
        setBusy( true );
        FtRapi.callList( 'VoteCaptureInfoNomin', {
            success: ( result, data ) => {
                setMdlPosition( data[0] );
                setMdlRound( data[1] );
                setLkpCandidate( data[2] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onSave(): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to capture this candidate?", { onA: () => {
            setBusy( true );
            FtRapi.callNew( 'VoteCandidateNew', {
                inRec: mdlCandidate,
                success: ( result, data ) => {
                    setMdlCandidate( new VotingCandidate() );
                    ftPopupMessage( setPopup, "Success", result.message, {} );
                },
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ), // Ft.dirs( "ERROR:", result );
                complete: () => setBusy( false ),
            });
        } } );
    }
}

