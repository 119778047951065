import CandidateStatusBase from './base/candidateStatusBase';

//********************************************************************
// CandidateStatus class
// @FTGEN_PROGRESS: busy
export default class CandidateStatus extends CandidateStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static CS_OPEN_FOR_VOTING = 1;
    static CS_APPROVED_PRIMARY = 2;
    static CS_APPROVED_SECONDARY = 3;
    static CS_APPROVED_FINAL = 4;
    static CS_DISQUALIFIED = 5;
    // #FTGEN_CONSTS_Z#
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
