import UserAuthRoleTypeBase from './base/userAuthRoleTypeBase';

//********************************************************************
// UserAuthRoleType class
// @FTGEN_PROGRESS: busy
export default class UserAuthRoleType extends UserAuthRoleTypeBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static UART_SYSTEMADMIN = 1;
    static UART_NOB = 2;
    static UART_ADMINISTRATION = 3;
    static UART_ADMINJUSTICE = 4;
    static UART_NLF = 5;
    static UART_REGIONAL_SECRETARY = 6;
    static UART_REGIONAL_TREASURER = 7;
    static UART_ADMIN_CLERK = 8;
    static UART_CONF_SUPERVISOR = 9;
    static UART_CONF_RECEPTION_SCANNING = 10;
    static UART_CONF_GOODYBAG_SCANNING = 11;
    static UART_CONF_DISPLAY_STATS = 12;
    static UART_VOTING_MANAGER = 13;
    static UART_VOTING_CAPTURE_PRIMARY = 14;
    static UART_VOTING_APPROVAL_SECONDARY = 15;
    static UART_VOTING_APPROVAL_FINAL = 16;
    // #FTGEN_CONSTS_Z# 
    
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
