import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtFmTextareaField from '../first/ftFmTextareaField';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import VotingPosition  from '../models/votingPosition';
import VotingRound     from '../models/votingRound';
import VotingCandidate from '../models/votingCandidate';
import CandidateStatus from '../models/candidateStatus';

//********************************************************************
// PageVoteApprovalFinal
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteApprovalFinal( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup           , setPopup           ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy            , setBusy            ] = useState( false );
    const [ mdlPosition     , setMdlPosition     ] = useState<VotingPosition|null>( null );
    const [ mdlRound        , setMdlRound        ] = useState<VotingRound|null>( null );
    const [ mdlCandidate    , setMdlCandidate    ] = useState<VotingCandidate>( new VotingCandidate() );
    const [ lstCandidate    , setLstCandidate    ] = useState<VotingCandidate[]>( [] );
    const [ showReject      , setShowReject      ] = useState<boolean>( false );
    const [ formKey         , setFormKey         ] = useState<string>( Ft.randomStr() ); // used to force rerender of form

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlPosition, mdlRound ) ) return ftBusy( { popup: popup } );

    /*
    PREMISE:
    this function selects finalists or disquilifies candidates based on council approval
    candidates can only be disqualified during nomination round

    ACTIONS:
    remove from finalists
    make finalist
    disqualify
    requalify

    DESIGN:
    === Current Status ===
    Open Position : None/<name>
    Open Round : None/<name>
    ======================

    === Reject Candidate ===========
    Name      : <fullname>
    Reason    : <text-input>
    ==============================

    === Finalists ===============
     CANDIDATE       VOTES          ACTIONS
     <name>          <votes>        [Remove from Finalists]
    =============================

    === Candidates ==============
     CANDIDATE       VOTES          ACTIONS
     <name>          <votes>        [Make Finalist] [Disqualify]
    =============================

    === Rejected Candidates =====
     CANDIDATE       VOTES          ACTIONS
     <name>          <votes>        [Requalify]
    =============================
    */

    const txtStatus: any = {
        ss: '12',
        txtDescr: '20,WCO#273891',
    };
    const txtHeading: any = {
        txtDescr: '20,WCO#005493'
    };
    const txtItem: any = {
        txtDescr: '18,WCO#000000'
    };
    const btnProps: any = {
        clr     : "#008000",
        clrX    : "#cccccc",
        txtClr  : "#ffffff",
        txtClrX : "#000000",
        pad: 3
    };
    const ACT_FINALIST_SET: number = 1;
    const ACT_FINALIST_UNSET: number = 2;
    const ACT_DISQUALIFY_SET: number = 3;
    const ACT_DISQUALIFY_UNSET: number = 4;

    const roundName:string = mdlRound?.round_no == 0 ? 'Nomination' : `${mdlRound?.round_no}`;
    const isNomin: boolean = mdlRound?.round_no == 0;

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Current Voting Status' {...Th.frame}>
            <FtText {...txtStatus} text={`Open Position: ${mdlPosition?.name}`} />
            <FtText {...txtStatus} text={`Open Round : ${roundName}`} />
            <FtButton label="Refresh" {...btnProps} onTap={loadData} />
        </FtFrame>

        <FtFrame text='Disqualify Candidates' {...Th.frame} show={showReject}>
            <FtForm {...Th.form}
                key={formKey}
                models={{ m: mdlCandidate }}
                onSubmit={onSave}
            >
                <FtFmTextField     name='m.name'        label='Name'  {...Th.field} en={0} ss='9' />
                <FtFmTextareaField name='m.disq_reason' label='Reason' {...Th.field} ss='9' />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Disqualify Candidate' {...Th.button} />
            </FtForm>
        </FtFrame>

        <FtFrame text='Finalist Candidates' {...Th.frame} show={!showReject} md='rw'>
            <FtText ss='6' {...txtHeading} text='Candidate' />
            <FtText ss='2' {...txtHeading} text='Votes' />
            <FtText ss='4' {...txtHeading} text='Actions' />

            {lstCandidate.map( can => ( isFinalist( can ) && (<>
                <FtText ss='6' {...txtItem} text={can.name} />
                <FtText ss='2' {...txtItem} text={`${can.vote_count} (${can.vote_perc?.toFixed(2)} %)`} />
                <FtCon key={Ft.key()} ss='4' md='r'>
                    <FtButton key={Ft.key()} label="Remove from Finalists"  {...btnProps} onTap={() => finalistUnset( can )}   />
                </FtCon>
            </>)))}
            <FtSpacer />
            <FtCon md='r' lt='c,a' ss='12'>
                <FtButton label="Submit Finalists" {...btnProps} en={enableSubmit()} onTap={submitFinalists} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Candidates' {...Th.frame} show={!showReject} md='rw'>
            <FtText ss='6' {...txtHeading} text='Candidate' />
            <FtText ss='2' {...txtHeading} text='Votes' />
            <FtText ss='4' {...txtHeading} text='Actions' />

            {lstCandidate.map( can => ( isNormal( can ) && (<>
                <FtText ss='6' {...txtItem} text={can.name} />
                <FtText ss='2' {...txtItem} text={`${can.vote_count} (${can.vote_perc?.toFixed(2)} %)`} />
                <FtCon key={Ft.key()} ss='4' md='r' show={showButtons( can )}>
                    <FtButton key={Ft.key()} label="Make Finalist" {...btnProps}                onTap={() => finalistSet( can )}   />
                    <FtButton key={Ft.key()} label="Disqualify"    {...btnProps} show={isNomin} onTap={() => disqualifySet( can )}    />
                </FtCon>
                <FtText ss='4' {...txtItem} text='Not approved by Secondary yet' show={!showButtons( can )} />
                <FtSpacer h={10} show={!showButtons( can )} />
            </>)))}
        </FtFrame>

        <FtFrame text='Disqualified Candidates' {...Th.frame} show={!showReject && isNomin} md='rw'>
            <FtText ss='6' {...txtHeading} text='Candidate' />
            <FtText ss='2' {...txtHeading} text='Votes' />
            <FtText ss='4' {...txtHeading} text='Actions' />

            {lstCandidate.map( can => ( isDisqualified( can ) && (<>
                <FtText ss='6' {...txtItem} text={can.name} />
                <FtText ss='2' {...txtItem} text={`${can.vote_count} (${can.vote_perc?.toFixed(2)} %)`} />
                <FtCon key={Ft.key()} ss='4' md='r'>
                    <FtButton key={Ft.key()} label="Remove Disqualification"  {...btnProps} onTap={() => disqualifyUnset( can )}   />
                </FtCon>
            </>)))}
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        loadData();
    }

    function loadData()
    {
        setBusy( true );
        FtRapi.callList( 'VoteApprovalInfo', {
            success: ( result, data ) => {
                setMdlPosition( data[0] );
                setMdlRound( data[1] );
                setLstCandidate( data[2] );
                setShowReject( false );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function isFinalist( can: VotingCandidate ): boolean
    {
        return can!.is_finalist == 1;
    }

    function isNormal( can: VotingCandidate ): boolean
    {
        return can!.is_finalist == 0 && can!.status_id != CandidateStatus.CS_DISQUALIFIED;
    }

    function isDisqualified( can: VotingCandidate ): boolean
    {
        return can!.is_finalist == 0 && can!.status_id == CandidateStatus.CS_DISQUALIFIED;
    }

    function showButtons( can: VotingCandidate ): boolean
    {
        return can.status_id == CandidateStatus.CS_APPROVED_SECONDARY;
    }

    function finalistSet( can: VotingCandidate ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to make this candidate a finalist?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteFinalApproval', can.id, {
                urlParms: { action: ACT_FINALIST_SET },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function finalistUnset( can: VotingCandidate ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to remove this candidate from the finalists?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteFinalApproval', can.id, {
                urlParms: { action: ACT_FINALIST_UNSET },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function disqualifySet( can: VotingCandidate ): void
    {
        const can2: VotingCandidate = new VotingCandidate();
        can2.id          = can.id;
        can2.name        = can.name;
        can2.disq_reason = '';
        setMdlCandidate( can2 );
        setFormKey( Ft.randomStr() );
        setShowReject( true );
    }

    function onSave(): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to disqualify this candidate?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteFinalApproval', mdlCandidate.id, {
                urlParms: {
                    action      : ACT_DISQUALIFY_SET,
                    disq_reason : mdlCandidate.disq_reason,
                },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function disqualifyUnset( can: VotingCandidate ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to remove this disqualification?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteFinalApproval', can.id, {
                urlParms: { action      : ACT_DISQUALIFY_UNSET },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function enableSubmit(): boolean
    {
        const allSecondApproved = Ft.lq( lstCandidate ).all( can => can.status_approve_second_dttm != null );
        const anyFinalists: boolean = Ft.lq( lstCandidate ).any( can => can.is_finalist == 1 );
        return allSecondApproved && anyFinalists;
    }

    function submitFinalists(): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to submit these finalists?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteRoundApprove', 0, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: nav.pop } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }
}