import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AssemblyPropertyBase class : Generated : DO NOT MODIFY
export default class AssemblyPropertyBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    oldid: number|null;
    physical_adr_1: string|null;
    physical_adr_2: string|null;
    physical_adr_area: string|null;
    physical_adr_region: string|null;
    physical_adr_city: string|null;
    physical_adr_country: string|null;
    assembly_id: number|null;
    assembly_name: string|null;
    assembly_no: string|null;
    erf_no: string|null;
    purch_value: Decimal|null;
    purch_date: Date|null;
    owner: string|null;
    phone: string|null;
    cell: string|null;
    email: string|null;
    notes: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.physical_adr_1 = '';
        this.physical_adr_2 = '';
        this.physical_adr_area = '';
        this.physical_adr_region = '';
        this.physical_adr_city = '';
        this.physical_adr_country = '';
        this.assembly_id = 0;
        this.assembly_name = '';
        this.assembly_no = '';
        this.erf_no = '';
        this.purch_value = new Decimal('0.0');
        this.purch_date = moment( '0001-01-01' ).toDate();
        this.owner = '';
        this.phone = '';
        this.cell = '';
        this.email = '';
        this.notes = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.physical_adr_1 = obj.physical_adr_1;
        this.physical_adr_2 = obj.physical_adr_2;
        this.physical_adr_area = obj.physical_adr_area;
        this.physical_adr_region = obj.physical_adr_region;
        this.physical_adr_city = obj.physical_adr_city;
        this.physical_adr_country = obj.physical_adr_country;
        this.assembly_id = obj.assembly_id;
        this.assembly_name = obj.assembly_name;
        this.assembly_no = obj.assembly_no;
        this.erf_no = obj.erf_no;
        this.purch_value = FtRapi.decodeDec( obj.purch_value );
        this.purch_date = FtRapi.decodeDate( obj.purch_date );
        this.owner = obj.owner;
        this.phone = obj.phone;
        this.cell = obj.cell;
        this.email = obj.email;
        this.notes = obj.notes;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            physical_adr_1 : FtRapi.encodeStr( this.physical_adr_1 ),
            physical_adr_2 : FtRapi.encodeStr( this.physical_adr_2 ),
            physical_adr_area : FtRapi.encodeStr( this.physical_adr_area ),
            physical_adr_region : FtRapi.encodeStr( this.physical_adr_region ),
            physical_adr_city : FtRapi.encodeStr( this.physical_adr_city ),
            physical_adr_country : FtRapi.encodeStr( this.physical_adr_country ),
            assembly_id : this.assembly_id,
            assembly_name : FtRapi.encodeStr( this.assembly_name ),
            assembly_no : FtRapi.encodeStr( this.assembly_no ),
            erf_no : FtRapi.encodeStr( this.erf_no ),
            purch_value : FtRapi.encodeDec( this.purch_value ),
            purch_date : FtRapi.encodeDate( this.purch_date ),
            owner : FtRapi.encodeStr( this.owner ),
            phone : FtRapi.encodeStr( this.phone ),
            cell : FtRapi.encodeStr( this.cell ),
            email : FtRapi.encodeStr( this.email ),
            notes : FtRapi.encodeStr( this.notes ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.physical_adr_1 = src.physical_adr_1;
        this.physical_adr_2 = src.physical_adr_2;
        this.physical_adr_area = src.physical_adr_area;
        this.physical_adr_region = src.physical_adr_region;
        this.physical_adr_city = src.physical_adr_city;
        this.physical_adr_country = src.physical_adr_country;
        this.assembly_id = Ft.parseInt0Null( src.assembly_id );
        this.assembly_name = src.assembly_name;
        this.assembly_no = src.assembly_no;
        this.erf_no = src.erf_no;
        this.purch_value = new Decimal( src.purch_value );
        this.purch_date = src.purch_date;
        this.owner = src.owner;
        this.phone = src.phone;
        this.cell = src.cell;
        this.email = src.email;
        this.notes = src.notes;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.physical_adr_1 = null;
        this.physical_adr_2 = null;
        this.physical_adr_area = null;
        this.physical_adr_region = null;
        this.physical_adr_city = null;
        this.physical_adr_country = null;
        this.assembly_id = null;
        this.assembly_name = null;
        this.assembly_no = null;
        this.erf_no = null;
        this.purch_value = null;
        this.purch_date = moment( '0001-01-01' ).toDate();
        this.owner = null;
        this.phone = null;
        this.cell = null;
        this.email = null;
        this.notes = null;
    }
}
