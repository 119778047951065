import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorHistoryBase class : Generated : DO NOT MODIFY
export default class PastorHistoryBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    oldid: number|null;
    title: string|null;
    compliance_asset_idx: string|null;
    pastid: number|null;
    member_no: string|null;
    accepted_date: Date|null;
    called_date: Date|null;
    position_no: string|null;
    region_no: string|null;
    assembly_name: string|null;
    assembly_no: string|null;
    special_reason: string|null;
    content_type: string|null;
    app_created_by: string|null;
    app_modified_by: string|null;
    workflow_instance_idx: string|null;
    file_type: string|null;
    modified: Date|null;
    created: Date|null;
    created_by: number|null;
    modified_by: number|null;
    url_path: string|null;
    path: string|null;
    item_type: string|null;
    encoded_absolute_url: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.title = '';
        this.compliance_asset_idx = '';
        this.pastid = 0;
        this.member_no = '';
        this.accepted_date = moment( '0001-01-01' ).toDate();
        this.called_date = moment( '0001-01-01' ).toDate();
        this.position_no = '';
        this.region_no = '';
        this.assembly_name = '';
        this.assembly_no = '';
        this.special_reason = '';
        this.content_type = '';
        this.app_created_by = '';
        this.app_modified_by = '';
        this.workflow_instance_idx = '';
        this.file_type = '';
        this.modified = moment( '0001-01-01 00:00:00' ).toDate();
        this.created = moment( '0001-01-01 00:00:00' ).toDate();
        this.created_by = 0;
        this.modified_by = 0;
        this.url_path = '';
        this.path = '';
        this.item_type = '';
        this.encoded_absolute_url = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.title = obj.title;
        this.compliance_asset_idx = obj.compliance_asset_idx;
        this.pastid = obj.pastid;
        this.member_no = obj.member_no;
        this.accepted_date = FtRapi.decodeDate( obj.accepted_date );
        this.called_date = FtRapi.decodeDate( obj.called_date );
        this.position_no = obj.position_no;
        this.region_no = obj.region_no;
        this.assembly_name = obj.assembly_name;
        this.assembly_no = obj.assembly_no;
        this.special_reason = obj.special_reason;
        this.content_type = obj.content_type;
        this.app_created_by = obj.app_created_by;
        this.app_modified_by = obj.app_modified_by;
        this.workflow_instance_idx = obj.workflow_instance_idx;
        this.file_type = obj.file_type;
        this.modified = FtRapi.decodeDttm( obj.modified );
        this.created = FtRapi.decodeDttm( obj.created );
        this.created_by = obj.created_by;
        this.modified_by = obj.modified_by;
        this.url_path = obj.url_path;
        this.path = obj.path;
        this.item_type = obj.item_type;
        this.encoded_absolute_url = obj.encoded_absolute_url;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            title : FtRapi.encodeStr( this.title ),
            compliance_asset_idx : FtRapi.encodeStr( this.compliance_asset_idx ),
            pastid : this.pastid,
            member_no : FtRapi.encodeStr( this.member_no ),
            accepted_date : FtRapi.encodeDate( this.accepted_date ),
            called_date : FtRapi.encodeDate( this.called_date ),
            position_no : FtRapi.encodeStr( this.position_no ),
            region_no : FtRapi.encodeStr( this.region_no ),
            assembly_name : FtRapi.encodeStr( this.assembly_name ),
            assembly_no : FtRapi.encodeStr( this.assembly_no ),
            special_reason : FtRapi.encodeStr( this.special_reason ),
            content_type : FtRapi.encodeStr( this.content_type ),
            app_created_by : FtRapi.encodeStr( this.app_created_by ),
            app_modified_by : FtRapi.encodeStr( this.app_modified_by ),
            workflow_instance_idx : FtRapi.encodeStr( this.workflow_instance_idx ),
            file_type : FtRapi.encodeStr( this.file_type ),
            modified : FtRapi.encodeDttm( this.modified ),
            created : FtRapi.encodeDttm( this.created ),
            created_by : this.created_by,
            modified_by : this.modified_by,
            url_path : FtRapi.encodeStr( this.url_path ),
            path : FtRapi.encodeStr( this.path ),
            item_type : FtRapi.encodeStr( this.item_type ),
            encoded_absolute_url : FtRapi.encodeStr( this.encoded_absolute_url ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.title = src.title;
        this.compliance_asset_idx = src.compliance_asset_idx;
        this.pastid = Ft.parseInt0Null( src.pastid );
        this.member_no = src.member_no;
        this.accepted_date = src.accepted_date;
        this.called_date = src.called_date;
        this.position_no = src.position_no;
        this.region_no = src.region_no;
        this.assembly_name = src.assembly_name;
        this.assembly_no = src.assembly_no;
        this.special_reason = src.special_reason;
        this.content_type = src.content_type;
        this.app_created_by = src.app_created_by;
        this.app_modified_by = src.app_modified_by;
        this.workflow_instance_idx = src.workflow_instance_idx;
        this.file_type = src.file_type;
        this.modified = src.modified;
        this.created = src.created;
        this.created_by = Ft.parseInt0Null( src.created_by );
        this.modified_by = Ft.parseInt0Null( src.modified_by );
        this.url_path = src.url_path;
        this.path = src.path;
        this.item_type = src.item_type;
        this.encoded_absolute_url = src.encoded_absolute_url;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.title = null;
        this.compliance_asset_idx = null;
        this.pastid = null;
        this.member_no = null;
        this.accepted_date = moment( '0001-01-01' ).toDate();
        this.called_date = moment( '0001-01-01' ).toDate();
        this.position_no = null;
        this.region_no = null;
        this.assembly_name = null;
        this.assembly_no = null;
        this.special_reason = null;
        this.content_type = null;
        this.app_created_by = null;
        this.app_modified_by = null;
        this.workflow_instance_idx = null;
        this.file_type = null;
        this.modified = moment( '0001-01-01 00:00:00' ).toDate();
        this.created = moment( '0001-01-01 00:00:00' ).toDate();
        this.created_by = null;
        this.modified_by = null;
        this.url_path = null;
        this.path = null;
        this.item_type = null;
        this.encoded_absolute_url = null;
    }
}
