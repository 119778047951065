import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// PastorQualificationBase class : Generated : DO NOT MODIFY
export default class PastorQualificationBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    oldid: number|null;
    pastor_id: number|null;
    member_no: string|null;
    description: string|null;
    category: string|null;
    awarding_date: string|null;
    accredited_by: string|null;
    obtained_from: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.pastor_id = 0;
        this.member_no = '';
        this.description = '';
        this.category = '';
        this.awarding_date = '';
        this.accredited_by = '';
        this.obtained_from = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.pastor_id = obj.pastor_id;
        this.member_no = obj.member_no;
        this.description = obj.description;
        this.category = obj.category;
        this.awarding_date = obj.awarding_date;
        this.accredited_by = obj.accredited_by;
        this.obtained_from = obj.obtained_from;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            pastor_id : this.pastor_id,
            member_no : FtRapi.encodeStr( this.member_no ),
            description : FtRapi.encodeStr( this.description ),
            category : FtRapi.encodeStr( this.category ),
            awarding_date : FtRapi.encodeStr( this.awarding_date ),
            accredited_by : FtRapi.encodeStr( this.accredited_by ),
            obtained_from : FtRapi.encodeStr( this.obtained_from ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.pastor_id = Ft.parseInt0Null( src.pastor_id );
        this.member_no = src.member_no;
        this.description = src.description;
        this.category = src.category;
        this.awarding_date = src.awarding_date;
        this.accredited_by = src.accredited_by;
        this.obtained_from = src.obtained_from;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.pastor_id = null;
        this.member_no = null;
        this.description = null;
        this.category = null;
        this.awarding_date = null;
        this.accredited_by = null;
        this.obtained_from = null;
    }
}
