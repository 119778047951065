import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AssemblyTypeBase class : Generated : DO NOT MODIFY
export default class AssemblyTypeBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    name: string|null;
    description: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.name = '';
        this.description = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            name : FtRapi.encodeStr( this.name ),
            description : FtRapi.encodeStr( this.description ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.name = src.name;
        this.description = src.description;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.name = null;
        this.description = null;
    }
}
