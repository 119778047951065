import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// UserzBase class : Generated : DO NOT MODIFY
export default class UserzBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    username: string|null;
    password: string|null;
    disp_name: string|null;
    email: string|null;
    init: boolean|null;
    init_code: string|null;
    active: boolean|null;
    pass_reset: boolean|null;
    user_type_id: number|null;
    create_dttm: Date|null;
    last_login: Date|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.username = '';
        this.password = '';
        this.disp_name = '';
        this.email = '';
        this.init = false;
        this.init_code = '';
        this.active = false;
        this.pass_reset = false;
        this.user_type_id = 0;
        this.create_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.last_login = moment( '0001-01-01 00:00:00' ).toDate();
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.username = obj.username;
        this.password = obj.password;
        this.disp_name = obj.disp_name;
        this.email = obj.email;
        this.init = obj.init;
        this.init_code = obj.init_code;
        this.active = obj.active;
        this.pass_reset = obj.pass_reset;
        this.user_type_id = obj.user_type_id;
        this.create_dttm = FtRapi.decodeDttm( obj.create_dttm );
        this.last_login = FtRapi.decodeDttm( obj.last_login );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            username : FtRapi.encodeStr( this.username ),
            password : FtRapi.encodeStr( this.password ),
            disp_name : FtRapi.encodeStr( this.disp_name ),
            email : FtRapi.encodeStr( this.email ),
            init : FtRapi.encodeBool( this.init ),
            init_code : FtRapi.encodeStr( this.init_code ),
            active : FtRapi.encodeBool( this.active ),
            pass_reset : FtRapi.encodeBool( this.pass_reset ),
            user_type_id : this.user_type_id,
            create_dttm : FtRapi.encodeDttm( this.create_dttm ),
            last_login : FtRapi.encodeDttm( this.last_login ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.username = src.username;
        this.password = src.password;
        this.disp_name = src.disp_name;
        this.email = src.email;
        this.init = Ft.parseBool( src.init );
        this.init_code = src.init_code;
        this.active = Ft.parseBool( src.active );
        this.pass_reset = Ft.parseBool( src.pass_reset );
        this.user_type_id = Ft.parseInt0Null( src.user_type_id );
        this.create_dttm = src.create_dttm;
        this.last_login = src.last_login;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.username = null;
        this.password = null;
        this.disp_name = null;
        this.email = null;
        this.init = false;
        this.init_code = null;
        this.active = false;
        this.pass_reset = false;
        this.user_type_id = null;
        this.create_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.last_login = moment( '0001-01-01 00:00:00' ).toDate();
    }
}
