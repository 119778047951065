import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageMenuConfReception
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuConfReception( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Menu' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Registrations List"                {...Th.menuBtn}    {...stl}  show={testRegisList()}       onTap={onTapRegisList} />
                <FtButton label="Scan Attendees"                    {...Th.menuBtn}    {...stl}  show={testScanAttendees()}   onTap={onTapScanAttendees} />
                <FtButton label="Scan Goody-Bags"                   {...Th.menuBtn}    {...stl}  show={testScanGoody()}       onTap={onTapScanGoody} />
                {/* <FtButton label="Manage Attendees"                  {...Th.menuBtnX}   {...stl}  show={testManageAtts()} /* onTap={onTapManageAtts} * / /> */}
                <FtButton label="Reception Stats"                   {...Th.menuBtn}    {...stl}  show={testRecepStats()}      onTap={onTapRecepStats} />
                <FtButton label="Report: Reception Summary"         {...Th.menuBtn}    {...stl}  show={testRecepSumReport()}  onTap={onTapRecepSumReport} />
                <FtButton label="Report: Reception Breakdown"       {...Th.menuBtn}    {...stl}  show={testRecepDetailReport()}  onTap={onTapRecepDetailReport} />
                {/* <FtButton label="Reception List Report"             {...Th.menuBtnX}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} /* onTap={() => nav.push( 'ReportConfregisSummaryTotal' )} * / /> */}
                <FtSpacer />
                <FtButton label="Back to Main Menu"                 {...Th.menuBtn}   {...stl}  onTap={nav.pop} />
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function testRegisList(): boolean
    {
        return Ut.roleTest( Ut.RoleT.CONF_RECEPTION_SCANNING, Ut.RoleT.ADMIN_CLERK );
    }

    function onTapRegisList(): void
    {
        nav.push( 'AttendeeSearch' );
    }

    function testScanAttendees(): boolean
    {
        return Ut.roleTest( Ut.RoleT.CONF_RECEPTION_SCANNING, Ut.RoleT.ADMIN_CLERK );
    }

    function onTapScanAttendees(): void
    {
        nav.push( 'ConfrecepScan' );
    }

    function testScanGoody(): boolean
    {
        return Ut.roleTest( Ut.RoleT.CONF_GOODYBAG_SCANNING, Ut.RoleT.ADMIN_CLERK );
    }

    function onTapScanGoody(): void
    {
        nav.push( 'ConfrecepGoodyScan' );
    }

    function testManageAtts(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK );
    }

    function onTapManageAtts(): void
    {
        nav.push( 'ConfregisStatistics' );
    }    

    function testRecepStats(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_DISPLAY_STATS );
    }

    function onTapRecepStats(): void
    {
        nav.push( 'ConfrecepStatistics' );
    }    

    function testRecepSumReport(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_SUPERVISOR );
    }

    function onTapRecepSumReport(): void
    {
        nav.push( 'ReportConfrecepSummary' );
    }

    function testRecepDetailReport(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_SUPERVISOR );
    }

    function onTapRecepDetailReport(): void
    {
        nav.push( 'ReportConfrecepDetail' );
    }


}
