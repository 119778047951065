import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// JusticeNoteBase class : Generated : DO NOT MODIFY
export default class JusticeNoteBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    oldid: number|null;
    oldid2: number|null;
    userz_id: number|null;
    userz_who: string|null;
    entry_date: Date|null;
    description: string|null;
    member_no: string|null;
    justice_id: number|null;
    justice_case_no: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.oldid2 = 0;
        this.userz_id = 0;
        this.userz_who = '';
        this.entry_date = moment( '0001-01-01' ).toDate();
        this.description = '';
        this.member_no = '';
        this.justice_id = 0;
        this.justice_case_no = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.oldid2 = obj.oldid2;
        this.userz_id = obj.userz_id;
        this.userz_who = obj.userz_who;
        this.entry_date = FtRapi.decodeDate( obj.entry_date );
        this.description = obj.description;
        this.member_no = obj.member_no;
        this.justice_id = obj.justice_id;
        this.justice_case_no = obj.justice_case_no;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            oldid2 : this.oldid2,
            userz_id : this.userz_id,
            userz_who : FtRapi.encodeStr( this.userz_who ),
            entry_date : FtRapi.encodeDate( this.entry_date ),
            description : FtRapi.encodeStr( this.description ),
            member_no : FtRapi.encodeStr( this.member_no ),
            justice_id : this.justice_id,
            justice_case_no : FtRapi.encodeStr( this.justice_case_no ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.oldid2 = Ft.parseInt0Null( src.oldid2 );
        this.userz_id = Ft.parseInt0Null( src.userz_id );
        this.userz_who = src.userz_who;
        this.entry_date = src.entry_date;
        this.description = src.description;
        this.member_no = src.member_no;
        this.justice_id = Ft.parseInt0Null( src.justice_id );
        this.justice_case_no = src.justice_case_no;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.oldid2 = null;
        this.userz_id = null;
        this.userz_who = null;
        this.entry_date = moment( '0001-01-01' ).toDate();
        this.description = null;
        this.member_no = null;
        this.justice_id = null;
        this.justice_case_no = null;
    }
}
