import VotingPositionBase from './base/votingPositionBase';

//********************************************************************
// VotingPosition class
// @FTGEN_PROGRESS: busy
export default class VotingPosition extends VotingPositionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
