import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';


//********************************************************************
// PageMenuVoting
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenuVoting( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    // useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Menu' {...Th.menuFrame}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Manage Process"               {...Th.menuBtn}    {...stl}  show={testManagerPrc()}             onTap={onTapManagerPrc} />
                <FtButton label="Capture Nominations"          {...Th.menuBtn}    {...stl}  show={testCaptureNominations()}     onTap={onTapCaptureNominations} />
                <FtButton label="Capture / Approval Primary"   {...Th.menuBtn}    {...stl}  show={testCapturePrimary()}         onTap={onTapCapturePrimary} />
                <FtButton label="Approval Secondary"           {...Th.menuBtn}    {...stl}  show={testApprovalSecondary()}      onTap={onTapApprovalSecondary} />
                <FtButton label="Approval Final"               {...Th.menuBtn}    {...stl}  show={testApprovalFinal()}          onTap={onTapApprovalFinal} />
                <FtButton label="Display Round Results"        {...Th.menuBtn}    {...stl}  show={testDisplayRoundResults()}    onTap={onTapDisplayRoundResults} />
                <FtButton label="Display Voting Status"        {...Th.menuBtn}    {...stl}  show={testDisplayVotingStatus()}    onTap={onTapDisplayVotingStatus} />
                {/*
                <FtButton label="Candidates Search"            {...Th.menuBtnX}   {...stl}  show={testCandidatesSearch()}       / * onTap={onTapCandidatesSearch} * / />
                <FtButton label="Voting Results List"          {...Th.menuBtnX}   {...stl}  show={testVotingResultsList()}      / * onTap={onTapVotingResultsList} * / />
                <FtButton label="Report Votes List"            {...Th.menuBtnX}   {...stl}  show={testReportVotesList()}        /* onTap={onTapReportVotesList} * / />
                */}
                <FtButton label="Report Voting Results"        {...Th.menuBtn}    {...stl}  show={testReportVotingResults()}    onTap={onTapReportVotingResults} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"            {...Th.menuBtn}   {...stl}  onTap={nav.pop} />
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function testManagerPrc(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapManagerPrc(): void
    {
        nav.push( 'VoteProcessManager' );
    }

    function testCandidatesSearch(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapCandidatesSearch(): void
    {
        nav.push( 'VoteCandidateSearch' );
    }

    function testCaptureNominations(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_CAPTURE_PRIMARY );
    }

    function onTapCaptureNominations(): void
    {
        nav.push( 'VoteCaptureNomin' );
    }

    function testCapturePrimary(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_CAPTURE_PRIMARY );
    }

    function onTapCapturePrimary(): void
    {
        nav.push( 'VoteApprovalPrimary' );
    }

    function testApprovalSecondary(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_APPROVAL_SECONDARY );
    }

    function onTapApprovalSecondary(): void
    {
        nav.push( 'VoteApprovalSecondary' );
    }

    function testApprovalFinal(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_APPROVAL_FINAL );
    }

    function onTapApprovalFinal(): void
    {
        nav.push( 'VoteApprovalFinal' );
    }

    function testDisplayVotingStatus(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_DISPLAY_STATS );
    }

    function onTapDisplayVotingStatus(): void
    {
        nav.push( 'VoteDisplayStatus' );
    }

    function testDisplayRoundResults(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapDisplayRoundResults(): void
    {
        nav.push( 'VoteDisplayResults' );
    }

    function testDisplayPositionResults(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapDisplayPositionResults(): void
    {
        nav.push( 'VotePositionResults' );
    }

    function testVotingResultsList(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapVotingResultsList(): void
    {
        nav.push( 'VoteResultsSearch' );
    }

    function testReportVotesList(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapReportVotesList(): void
    {
        nav.push( 'ReportVotesList' );
    }

    function testReportVotingResults(): boolean
    {
        return Ut.roleTest( Ut.RoleT.VOTING_MANAGER );
    }

    function onTapReportVotingResults(): void
    {
        nav.push( 'ReportVoteResults' );
    }

}
