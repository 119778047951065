import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// AdminTaskBase class : Generated : DO NOT MODIFY
export default class AdminTaskBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    tid: number|null;
    create_date: Date|null;
    description: string|null;
    category_id: number|null;
    category_name: string|null;
    responsible_id: number|null;
    responsible_user: string|null;
    is_done: boolean|null;
    due_by: Date|null;
    member_no: string|null;
    assembly_id: number|null;
    assembly_no: string|null;
    request_cat: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.tid = 0;
        this.create_date = moment( '0001-01-01 00:00:00' ).toDate();
        this.description = '';
        this.category_id = 0;
        this.category_name = '';
        this.responsible_id = 0;
        this.responsible_user = '';
        this.is_done = false;
        this.due_by = moment( '0001-01-01 00:00:00' ).toDate();
        this.member_no = '';
        this.assembly_id = 0;
        this.assembly_no = '';
        this.request_cat = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.tid = obj.tid;
        this.create_date = FtRapi.decodeDttm( obj.create_date );
        this.description = obj.description;
        this.category_id = obj.category_id;
        this.category_name = obj.category_name;
        this.responsible_id = obj.responsible_id;
        this.responsible_user = obj.responsible_user;
        this.is_done = obj.is_done;
        this.due_by = FtRapi.decodeDttm( obj.due_by );
        this.member_no = obj.member_no;
        this.assembly_id = obj.assembly_id;
        this.assembly_no = obj.assembly_no;
        this.request_cat = obj.request_cat;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            tid : this.tid,
            create_date : FtRapi.encodeDttm( this.create_date ),
            description : FtRapi.encodeStr( this.description ),
            category_id : this.category_id,
            category_name : FtRapi.encodeStr( this.category_name ),
            responsible_id : this.responsible_id,
            responsible_user : FtRapi.encodeStr( this.responsible_user ),
            is_done : FtRapi.encodeBool( this.is_done ),
            due_by : FtRapi.encodeDttm( this.due_by ),
            member_no : FtRapi.encodeStr( this.member_no ),
            assembly_id : this.assembly_id,
            assembly_no : FtRapi.encodeStr( this.assembly_no ),
            request_cat : FtRapi.encodeStr( this.request_cat ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.tid = Ft.parseInt0Null( src.tid );
        this.create_date = src.create_date;
        this.description = src.description;
        this.category_id = Ft.parseInt0Null( src.category_id );
        this.category_name = src.category_name;
        this.responsible_id = Ft.parseInt0Null( src.responsible_id );
        this.responsible_user = src.responsible_user;
        this.is_done = Ft.parseBool( src.is_done );
        this.due_by = src.due_by;
        this.member_no = src.member_no;
        this.assembly_id = Ft.parseInt0Null( src.assembly_id );
        this.assembly_no = src.assembly_no;
        this.request_cat = src.request_cat;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.tid = null;
        this.create_date = moment( '0001-01-01 00:00:00' ).toDate();
        this.description = null;
        this.category_id = null;
        this.category_name = null;
        this.responsible_id = null;
        this.responsible_user = null;
        this.is_done = false;
        this.due_by = moment( '0001-01-01 00:00:00' ).toDate();
        this.member_no = null;
        this.assembly_id = null;
        this.assembly_no = null;
        this.request_cat = null;
    }
}
