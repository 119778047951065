import RoundStatusBase from './base/roundStatusBase';

//********************************************************************
// RoundStatus class
// @FTGEN_PROGRESS: busy
export default class RoundStatus extends RoundStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static RS_CREATED = 1;
    static RS_OPEN_FOR_VOTING = 2;
    static RS_PROCESSING = 3;
    static RS_APPROVED_FINAL = 4;
    static RS_COMPLETED = 5;
    // #FTGEN_CONSTS_Z#
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
