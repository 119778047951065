import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import Th from '../general/th';
import Ut from '../general/ut';

//********************************************************************
// PageMenu
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageMenu( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup                  , setPopup                  ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy                   , setBusy                   ] = useState<boolean>( false );
    const [ showAdmin              , setShowAdmin              ] = useState<boolean>( true );
    const [ showManagement         , setShowManagement         ] = useState<boolean>( false );
    const [ showProvinces          , setShowProvinces          ] = useState<boolean>( false );
    const [ showRegions            , setShowRegions            ] = useState<boolean>( false );
    const [ showAssemblies         , setShowAssemblies         ] = useState<boolean>( false );
    const [ showPastors            , setShowPastors            ] = useState<boolean>( false );
    const [ showJustice            , setShowJustice            ] = useState<boolean>( false );
    const [ showRegSecretary       , setShowRegSecretary       ] = useState<boolean>( false );
    const [ showTreasurers         , setShowTreasurers         ] = useState<boolean>( false );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy ) return ftBusy( { popup: popup } );

    const stl: any = { txtClr: '#ffffff' };

    return (<FtCanvas {...Th.page} lt='a,a'>
        {popup}

        <FtFrame text='Administration' {...Th.menuFrame} show={showAdmin}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Dashboard"                 {...Th.menuBtnX}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} /* onTap={() => display( 'ConfVoting' )} */ />
                <FtButton label="Management"                {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN  )} onTap={() => display( 'Management' )} />
                <FtButton label="Provinces"                 {...Th.menuBtnX}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN  )} /* onTap={() => display( 'Provinces' )} */ />
                <FtButton label="Regions"                   {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Regions' )} />
                <FtButton label="Assemblies"                {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Assemblies' )} />
                <FtButton label="Pastors"                   {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Pastors' )} />
                <FtButton label="Pastors Search (Mobile)"   {...Th.menuBtnM}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => nav.push("PastorSearchMob")} />
                <FtButton label="Justice Investigations"    {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMINJUSTICE )} onTap={() => display( 'Justice' )} />
                <FtButton label="Regional Secretaries"      {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} /* onTap={() => display( 'RegSecretary' )} */ />
                <FtButton label="Regional Treusurers"       {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} /* onTap={() => display( 'Treasurers' )} */ />
                <FtButton label="Conference Registrations"  {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => nav.push( 'MenuConfRegister' )} />
                <FtButton label="Conference Reception"      {...Th.menuBtn}   {...stl}  show={testConfReception()}                    onTap={onTapConfReception} />
                <FtButton label="GBM Voting"                {...Th.menuBtn}   {...stl}
                    show={Ut.roleTest(
                        Ut.RoleT.ADMIN_CLERK,
                        Ut.RoleT.VOTING_CAPTURE_PRIMARY,
                        Ut.RoleT.VOTING_CAPTURE_PRIMARY,
                        Ut.RoleT.VOTING_APPROVAL_SECONDARY,
                        Ut.RoleT.VOTING_APPROVAL_FINAL,
                        Ut.RoleT.CONF_DISPLAY_STATS
                    )}
                    onTap={() => nav.push( 'MenuVoting' )}
                />
                <FtButton label="Registration Cards"        {...Th.menuBtn}   {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push( 'ConfregisCards' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Management' {...Th.menuFrame} show={showManagement}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="User Access List"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("UserSearch")} />
                <FtButton label="User Task List"            {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("TaskSearch")} />
                {/*
                <FtButton label="Voting"                    {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                <FtButton label="Titles List"               {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                <FtButton label="Positions List"            {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                <FtButton label="Status List"               {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                <FtButton label="Languages List"            {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                <FtButton label="Cities List"               {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.SYSTEMADMIN )} onTap={() => nav.push("?")} />
                */}
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Provinces' {...Th.menuFrame} show={showProvinces}>
            <FtCon {...Th.menuBlock}>
            </FtCon>
        </FtFrame>

        <FtFrame text='Regions' {...Th.menuFrame} show={showRegions}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Regions List"                              {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("RegionSearch")} />
                <FtButton label="Report: Regional List"                     {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionList")} />
                <FtButton label="Report: Regional Office Bearers"           {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionOfficeBearers")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Assemblies' {...Th.menuFrame} show={showAssemblies}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Assemblies List"                           {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("AssemblySearch")} />
                <FtButton label="Report: Assembly Secretary Labels"         {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportLabelAssemblySecretary")} />
                <FtButton label="Report: Assembly Added in Last 6 Months"   {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyAddedLast6Months")} />
                <FtButton label="Report: Assemblies Without Pastors"        {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNoPastors")} />
                <FtButton label="Report: Assembly Number List"              {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNumberList")} />
                <FtButton label="Report: Assembly Number List Sum"          {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyNumberListSum")} />
                <FtButton label="Report: Assembly Pastors List"             {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportAssemblyPastorsList")} />
                <FtButton label="Report: Labels Without Mail Addresses"     {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportLabelNoMailAddresses")} />
                <FtButton label="Report: Regional List"                     {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionList")} />
                <FtButton label="Report: Regional Office Bearers"           {...Th.menuBtn}    {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportRegionOfficeBearers")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Pastors' {...Th.menuFrame} show={showPastors}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Pastors List"                              {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("PastorSearch")} />
                <FtButton label="Report: Marriage Officer Pastors"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMarriageOfficers")} />
                <FtButton label="Report: Pastors by Gender"                 {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorGender")} />
                <FtButton label="Report: Pastor Re-Instated"                {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorReinstated")} />
                <FtButton label="Report: Pastors 40 Year Ministry"          {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastor40Year")} />
                <FtButton label="Report: Pastors Renewed"                   {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorRenewed")} />
                <FtButton label="Report: Pastors Seconded All"              {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorSecondedAll")} />
                <FtButton label="Report: Pastors Seconded to Forces"        {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorSecondedForces")} />
                <FtButton label="Report: Pastors Status"                    {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatus")} />
                <FtButton label="Report: Pastors Status Lapsed"             {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatusLapsed")} />
                <FtButton label="Report: Pastors Status Lapsed Warning"     {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastoralStatusLapsedWarning")} />
                <FtButton label="Report: Duplicates Pastors"                {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorDuplicates")} />
                <FtButton label="Report: Pastors Movement Accepted"         {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMovementAccepted")} />
                <FtButton label="Report: Pastors Movement Called"           {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK )} onTap={() => nav.push("ReportPastorMovementCalled")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Justice Investigations' {...Th.menuFrame} show={showJustice}>
            <FtCon {...Th.menuBlock}>
                <FtButton label="Justice Cases List"        {...Th.menuBtn}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMINJUSTICE )} onTap={() => nav.push("JusticeSearch")} />
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Regional Secretaries' {...Th.menuFrame} show={showRegSecretary}>
            <FtCon {...Th.menuBlock}>
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

        <FtFrame text='Treasuraers' {...Th.menuFrame}  show={showTreasurers}>
            <FtCon {...Th.menuBlock}>
                <FtSpacer />
                <FtButton label="Back to Main Menu"         {...Th.menuBtnW}  {...stl}  show={Ut.roleTest( Ut.RoleT.ADMIN_CLERK  )} onTap={() => display( 'Admin' )} />
            </FtCon>
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        setBusy( true );
        Ut.loadUser( () => setBusy( false ) );
    }

    function display( name: string ): void
    {
        setShowAdmin( false );
        setShowManagement( false );
        setShowProvinces( false );
        setShowRegions( false );
        setShowAssemblies( false );
        setShowPastors( false );
        setShowJustice( false );
        setShowRegSecretary( false );
        setShowTreasurers( false );

        switch ( name ) {
            case 'Admin':          setShowAdmin( true ); break;
            case 'Management':     setShowManagement( true ); break;
            case 'Provinces':      setShowProvinces( true ); break;
            case 'Regions':        setShowRegions( true ); break;
            case 'Assemblies':     setShowAssemblies( true ); break;
            case 'Pastors':        setShowPastors( true ); break;
            case 'Justice':        setShowJustice( true ); break;
            case 'RegSecretary':   setShowRegSecretary( true ); break;
            case 'Treasurers':     setShowTreasurers( true ); break;
        }
    }

    function testConfReception(): boolean
    {
        return Ut.roleTest( Ut.RoleT.ADMIN_CLERK, Ut.RoleT.CONF_RECEPTION_SCANNING, Ut.RoleT.CONF_GOODYBAG_SCANNING, Ut.RoleT.CONF_DISPLAY_STATS );
    }

    function onTapConfReception(): void
    {
        nav.push( 'MenuConfReception' );
    }

}
