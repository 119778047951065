import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// ArcRepoBase class : Generated : DO NOT MODIFY
export default class ArcRepoBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    upload_dttm: Date|null;
    store_dttm: Date|null;
    repo_key: string|null;
    size: number|null;
    ext: string|null;
    media_type_id: number|null;
    hash: string|null;
    title: string|null;
    descr: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.upload_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.store_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.repo_key = '';
        this.size = 0;
        this.ext = '';
        this.media_type_id = 0;
        this.hash = '';
        this.title = '';
        this.descr = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.upload_dttm = FtRapi.decodeDttm( obj.upload_dttm );
        this.store_dttm = FtRapi.decodeDttm( obj.store_dttm );
        this.repo_key = obj.repo_key;
        this.size = obj.size;
        this.ext = obj.ext;
        this.media_type_id = obj.media_type_id;
        this.hash = obj.hash;
        this.title = obj.title;
        this.descr = obj.descr;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            upload_dttm : FtRapi.encodeDttm( this.upload_dttm ),
            store_dttm : FtRapi.encodeDttm( this.store_dttm ),
            repo_key : FtRapi.encodeStr( this.repo_key ),
            size : this.size,
            ext : FtRapi.encodeStr( this.ext ),
            media_type_id : this.media_type_id,
            hash : FtRapi.encodeStr( this.hash ),
            title : FtRapi.encodeStr( this.title ),
            descr : FtRapi.encodeStr( this.descr ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.upload_dttm = src.upload_dttm;
        this.store_dttm = src.store_dttm;
        this.repo_key = src.repo_key;
        this.size = Ft.parseInt0Null( src.size );
        this.ext = src.ext;
        this.media_type_id = Ft.parseInt0Null( src.media_type_id );
        this.hash = src.hash;
        this.title = src.title;
        this.descr = src.descr;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.upload_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.store_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.repo_key = null;
        this.size = null;
        this.ext = null;
        this.media_type_id = null;
        this.hash = null;
        this.title = null;
        this.descr = null;
    }
}
