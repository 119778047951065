import React, { useState, useEffect } from 'react';
import type { FtPageProps, FtDgAction } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmTextField from '../first/ftFmTextField';
import FtButton from '../first/ftButton';
import FtDataGrid from '../first/ftDataGrid';
import FtFmDropdownField from '../first/ftFmDropdownField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';
import Ut from '../general/ut';

import GenLookup         from '../models/genLookup';
import Attendee          from '../models/attendee';

//********************************************************************
// PageAttendeeSearch
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageAttendeeSearch( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup         , setPopup         ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy          , setBusy          ] = useState( false );
    const [ mdlAttendee   , setMdlAttendee   ] = useState<Attendee|null>( new Attendee() );
    const [ mdlGoody      , setMdlGoody      ] = useState<any>( { prm_goody: null } );
    const [ lkpAssembly   , setLkpAssembly   ] = useState<GenLookup[]>( [] );
    const [ lkpRegion     , setLkpRegion     ] = useState<GenLookup[]>( [] );
    const [ lkpProvince   , setLkpProvince   ] = useState<GenLookup[]>( [] );
    const [ lstAttendee   , setLstAttendee   ] = useState<GenLookup[]>( [] );
    const [ lkpGoody      , setLkpGoody      ] = useState<GenLookup[]>( [] );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpAssembly, lkpRegion, lkpProvince, lkpGoody ) ) return ftBusy( { popup: popup } );

    return (<FtCanvas {...Th.page}>
        {popup}

        <FtCon ss='12|12|12|9' md='rw' lt='z,a' marB={15}>
            <FtButton label="New Attendee" onTap={onTapAttendeeNew} {...Th.button} />
        </FtCon>

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ 
                    m: mdlAttendee,
                    m2: mdlGoody,
                }}
                onSubmit={onSearch}
            >
                <FtFmTextField     name='m.rsaid_no'       label='ID Number'   {...Th.field} />
                <FtFmTextField     name='m.email'          label='Email'       {...Th.field} />
                <FtFmTextField     name='m.surname'        label='Surname'     {...Th.field} />
                <FtFmTextField     name='m.fullnames'      label='Fullnames'   {...Th.field} />
                <FtFmTextField     name='m.cell_no'        label='Cell No'     {...Th.field} />
                <FtFmDropdownField name='m2.prm_goody'     label='Goody-Bag'   {...Th.field} data={lkpGoody} />
                <FtSpacer />
                <FtFmSubmit ss='3:6:3|9:3' label='Search' {...Th.button} />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame} ss='12'>
            <FtDataGrid {...Th.grid} pgsize={50}
                data={lstAttendee}
                cols={[
                    { fld: ''                    , hd: 'Edit'          , type: 'act'  , flex: 1,  fmt: 'mc-pencil' , clr: '#808080', act: onTapAttendeeEdit },
                    { fld: ''                    , hd: 'Print'         , type: 'act'  , flex: 1,  fmt: 'mc-printer', clr: '#808080', act: onTapAttendeePrint },
                    { fld: 'goodybag_received'   , hd: 'Goody'         , type: 'bool' , flex: 1,  fmt: ''          , clr: '#40a0ff' },
                    { fld: 'surname'             , hd: 'Surname'       , type: 'txt'  , flex: 3 },
                    { fld: 'fullnames'           , hd: 'Fullnames'     , type: 'txt'  , flex: 3 },
                    { fld: 'rsaid_no'            , hd: 'Rsaid No'      , type: 'txt'  , flex: 3 },
                    { fld: 'email'               , hd: 'Email'         , type: 'txt'  , flex: 3 },
                    { fld: 'cell_no'             , hd: 'Cell No'       , type: 'txt'  , flex: 3 },
                    { fld: 'capture_dttm'        , hd: 'Capture Date'  , type: 'dtm'  , flex: 3, fmt: 'YYYY/MM/DD' },
                    { fld: 'payment_date'        , hd: 'Payment Date'  , type: 'date' , flex: 2, fmt: 'YYYY/MM/DD' },
                    { fld: 'assembly_id'         , hd: 'Assembly'      , type: 'lkp'  , flex: 3, lkp: lkpAssembly },
                    { fld: 'province_id'         , hd: 'Province'      , type: 'lkp'  , flex: 3, lkp: lkpProvince },
                    { fld: 'region_id'           , hd: 'Region'        , type: 'lkp'  , flex: 3, lkp: lkpRegion },
                    { fld: ''                    , hd: 'Delete'        , type: 'act'  , flex: 1, fmt: 'fa-trash', clr: '#808080', act: onTapAttendeeDelete },                                        
                ]}
            />
        </FtFrame>


    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        //- load lookups -----
        setLkpGoody( [ 
            new GenLookup().copyFrom( { id: 1, name: 'Received'     } ),
            new GenLookup().copyFrom( { id: 0, name: 'Not-Received' } ),
        ] );
        setBusy( true );
        FtRapi.callList( 'assemblyLookup', {
            success: ( result, data ) => {
                setLkpAssembly( data[0] );
                setLkpRegion(   data[1] );

                FtRapi.callList( 'basicLookups', {
                    success: ( result, data ) => {
                        setBusy( false );
                        setLkpProvince(  data[1] );
                    },
                    error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: () => {} } ),
                });
            },
            error: ( result ) => ftPopupMessage( setPopup, "Error", result.message, { onClose: () => {} } ),
        });
    }

    function onSearch(): void
    {
        setLstAttendee( [] );
        setBusy( true );
        FtRapi.callList( 'attendeeSearch', {
            urlParms: {
                prm_rsaid_no  : mdlAttendee?.rsaid_no,
                prm_email     : mdlAttendee?.email,
                prm_surname   : mdlAttendee?.surname,
                prm_fullnames : mdlAttendee?.fullnames,
                prm_cell_no   : mdlAttendee?.cell_no,
                prm_goody     : mdlGoody.prm_goody,
            },
            success: ( result, data ) => setLstAttendee( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }

    function onTapAttendeeNew(): void
    {
        nav.push( 'AttendeeEdit', { attendeeId: 0 } );
    }

    function onTapAttendeeEdit( { dataId }: FtDgAction ): void
    {
        nav.push( 'AttendeeEdit', { attendeeId: dataId } );
    }    

    function onTapAttendeeDelete( { dataId }: FtDgAction ): void
    {
        ftPopupConfirm( setPopup, 'Confirmation', "Are you sure you want to delete this record?", {
            btnA: 'Yes', onA: () => {
                setLstAttendee( [] );
                setBusy( true );
                FtRapi.callDelete( 'attendeeDelete', dataId, {
                    success: ( { message }, data ) => ftPopupMessage( setPopup, "Success", message, { onClose: onSearch } ),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
                    complete: () => setBusy( false ),
                });
            },
            btnB: 'No', onB: () => {}
        });
    }    

    function onTapAttendeePrint( { dataId }: FtDgAction ): void
    {
        Ut.openReport( 'reportConfregisConfirmLetter', { attendee_id: dataId } );
    }

}
