import AuditActionBase from './base/auditActionBase';

//********************************************************************
// AuditAction class
// @FTGEN_PROGRESS: busy
export default class AuditAction extends AuditActionBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static AA_VOTE_POSITION_OPEN = 1;
    static AA_VOTE_POSITION_CLOSE = 2;
    static AA_VOTE_POSITION_REVERSE = 3;
    static AA_VOTE_ROUND_REVERSE = 4;
    static AA_VOTE_ROUND_PROCESS = 5;
    static AA_VOTE_ROUND_OPEN = 6;
    static AA_VOTE_ROUND_CLOSE = 7;
    static AA_VOTE_ROUND_SUBMIT = 8;
    static AA_VOTE_CANDIDATE_CAPTURE_NOMIN = 9;
    static AA_VOTE_CANDIDATE_VOTE_EDIT = 10;
    static AA_VOTE_CANDIDATE_DELETE = 11;
    static AA_VOTE_CANDIDATE_PRIMARY_APPROVE = 12;
    static AA_VOTE_CANDIDATE_SECONDARY_APPROVE = 13;
    static AA_VOTE_CANDIDATE_SECONDARY_REJECT = 14;
    static AA_VOTE_CANDIDATE_FINALIST_SET = 15;
    static AA_VOTE_CANDIDATE_FINALIST_UNSET = 16;
    static AA_VOTE_CANDIDATE_DISQUALIFIY_SET = 17;
    static AA_VOTE_CANDIDATE_DISQUALIFIY_UNSET = 18;
    // #FTGEN_CONSTS_Z#
    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
