import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// JusticeBase class : Generated : DO NOT MODIFY
export default class JusticeBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    oldid: number|null;
    oldid2: number|null;
    userz_id: number|null;
    userz_who: string|null;
    assembly_id: number|null;
    pastor_id: number|null;
    entry_date: Date|null;
    description: string|null;
    member_no: string|null;
    case_no: string|null;
    resolution: string|null;
    date_resolved: Date|null;
    resolutionb_expiry_date: Date|null;
    status_id: number|null;
    status_name: string|null;
    category_id: number|null;
    category_name: string|null;
    com_name: string|null;
    com_natid: string|null;
    com_affil: string|null;
    com_gender_id: number|null;
    com_gender_name: string|null;
    com_phone: string|null;
    com_fax: string|null;
    com_email: string|null;
    com_physical_adr_1: string|null;
    com_physical_adr_2: string|null;
    com_physical_adr_city: string|null;
    com_physical_adr_code: string|null;
    com_cocom: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.oldid = 0;
        this.oldid2 = 0;
        this.userz_id = 0;
        this.userz_who = '';
        this.assembly_id = 0;
        this.pastor_id = 0;
        this.entry_date = moment( '0001-01-01' ).toDate();
        this.description = '';
        this.member_no = '';
        this.case_no = '';
        this.resolution = '';
        this.date_resolved = moment( '0001-01-01' ).toDate();
        this.resolutionb_expiry_date = moment( '0001-01-01' ).toDate();
        this.status_id = 0;
        this.status_name = '';
        this.category_id = 0;
        this.category_name = '';
        this.com_name = '';
        this.com_natid = '';
        this.com_affil = '';
        this.com_gender_id = 0;
        this.com_gender_name = '';
        this.com_phone = '';
        this.com_fax = '';
        this.com_email = '';
        this.com_physical_adr_1 = '';
        this.com_physical_adr_2 = '';
        this.com_physical_adr_city = '';
        this.com_physical_adr_code = '';
        this.com_cocom = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.oldid = obj.oldid;
        this.oldid2 = obj.oldid2;
        this.userz_id = obj.userz_id;
        this.userz_who = obj.userz_who;
        this.assembly_id = obj.assembly_id;
        this.pastor_id = obj.pastor_id;
        this.entry_date = FtRapi.decodeDate( obj.entry_date );
        this.description = obj.description;
        this.member_no = obj.member_no;
        this.case_no = obj.case_no;
        this.resolution = obj.resolution;
        this.date_resolved = FtRapi.decodeDate( obj.date_resolved );
        this.resolutionb_expiry_date = FtRapi.decodeDate( obj.resolutionb_expiry_date );
        this.status_id = obj.status_id;
        this.status_name = obj.status_name;
        this.category_id = obj.category_id;
        this.category_name = obj.category_name;
        this.com_name = obj.com_name;
        this.com_natid = obj.com_natid;
        this.com_affil = obj.com_affil;
        this.com_gender_id = obj.com_gender_id;
        this.com_gender_name = obj.com_gender_name;
        this.com_phone = obj.com_phone;
        this.com_fax = obj.com_fax;
        this.com_email = obj.com_email;
        this.com_physical_adr_1 = obj.com_physical_adr_1;
        this.com_physical_adr_2 = obj.com_physical_adr_2;
        this.com_physical_adr_city = obj.com_physical_adr_city;
        this.com_physical_adr_code = obj.com_physical_adr_code;
        this.com_cocom = obj.com_cocom;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            oldid : this.oldid,
            oldid2 : this.oldid2,
            userz_id : this.userz_id,
            userz_who : FtRapi.encodeStr( this.userz_who ),
            assembly_id : this.assembly_id,
            pastor_id : this.pastor_id,
            entry_date : FtRapi.encodeDate( this.entry_date ),
            description : FtRapi.encodeStr( this.description ),
            member_no : FtRapi.encodeStr( this.member_no ),
            case_no : FtRapi.encodeStr( this.case_no ),
            resolution : FtRapi.encodeStr( this.resolution ),
            date_resolved : FtRapi.encodeDate( this.date_resolved ),
            resolutionb_expiry_date : FtRapi.encodeDate( this.resolutionb_expiry_date ),
            status_id : this.status_id,
            status_name : FtRapi.encodeStr( this.status_name ),
            category_id : this.category_id,
            category_name : FtRapi.encodeStr( this.category_name ),
            com_name : FtRapi.encodeStr( this.com_name ),
            com_natid : FtRapi.encodeStr( this.com_natid ),
            com_affil : FtRapi.encodeStr( this.com_affil ),
            com_gender_id : this.com_gender_id,
            com_gender_name : FtRapi.encodeStr( this.com_gender_name ),
            com_phone : FtRapi.encodeStr( this.com_phone ),
            com_fax : FtRapi.encodeStr( this.com_fax ),
            com_email : FtRapi.encodeStr( this.com_email ),
            com_physical_adr_1 : FtRapi.encodeStr( this.com_physical_adr_1 ),
            com_physical_adr_2 : FtRapi.encodeStr( this.com_physical_adr_2 ),
            com_physical_adr_city : FtRapi.encodeStr( this.com_physical_adr_city ),
            com_physical_adr_code : FtRapi.encodeStr( this.com_physical_adr_code ),
            com_cocom : FtRapi.encodeStr( this.com_cocom ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.oldid = Ft.parseInt0Null( src.oldid );
        this.oldid2 = Ft.parseInt0Null( src.oldid2 );
        this.userz_id = Ft.parseInt0Null( src.userz_id );
        this.userz_who = src.userz_who;
        this.assembly_id = Ft.parseInt0Null( src.assembly_id );
        this.pastor_id = Ft.parseInt0Null( src.pastor_id );
        this.entry_date = src.entry_date;
        this.description = src.description;
        this.member_no = src.member_no;
        this.case_no = src.case_no;
        this.resolution = src.resolution;
        this.date_resolved = src.date_resolved;
        this.resolutionb_expiry_date = src.resolutionb_expiry_date;
        this.status_id = Ft.parseInt0Null( src.status_id );
        this.status_name = src.status_name;
        this.category_id = Ft.parseInt0Null( src.category_id );
        this.category_name = src.category_name;
        this.com_name = src.com_name;
        this.com_natid = src.com_natid;
        this.com_affil = src.com_affil;
        this.com_gender_id = Ft.parseInt0Null( src.com_gender_id );
        this.com_gender_name = src.com_gender_name;
        this.com_phone = src.com_phone;
        this.com_fax = src.com_fax;
        this.com_email = src.com_email;
        this.com_physical_adr_1 = src.com_physical_adr_1;
        this.com_physical_adr_2 = src.com_physical_adr_2;
        this.com_physical_adr_city = src.com_physical_adr_city;
        this.com_physical_adr_code = src.com_physical_adr_code;
        this.com_cocom = src.com_cocom;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.oldid = null;
        this.oldid2 = null;
        this.userz_id = null;
        this.userz_who = null;
        this.assembly_id = null;
        this.pastor_id = null;
        this.entry_date = moment( '0001-01-01' ).toDate();
        this.description = null;
        this.member_no = null;
        this.case_no = null;
        this.resolution = null;
        this.date_resolved = moment( '0001-01-01' ).toDate();
        this.resolutionb_expiry_date = moment( '0001-01-01' ).toDate();
        this.status_id = null;
        this.status_name = null;
        this.category_id = null;
        this.category_name = null;
        this.com_name = null;
        this.com_natid = null;
        this.com_affil = null;
        this.com_gender_id = null;
        this.com_gender_name = null;
        this.com_phone = null;
        this.com_fax = null;
        this.com_email = null;
        this.com_physical_adr_1 = null;
        this.com_physical_adr_2 = null;
        this.com_physical_adr_city = null;
        this.com_physical_adr_code = null;
        this.com_cocom = null;
    }
}
