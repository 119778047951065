import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// UserDeviceBase class : Generated : DO NOT MODIFY
export default class UserDeviceBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    userz_id: number|null;
    device_lid: string|null;
    device_dttm: Date|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.userz_id = 0;
        this.device_lid = '';
        this.device_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.userz_id = obj.userz_id;
        this.device_lid = obj.device_lid;
        this.device_dttm = FtRapi.decodeDttm( obj.device_dttm );
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            userz_id : this.userz_id,
            device_lid : FtRapi.encodeStr( this.device_lid ),
            device_dttm : FtRapi.encodeDttm( this.device_dttm ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.userz_id = Ft.parseInt0Null( src.userz_id );
        this.device_lid = src.device_lid;
        this.device_dttm = src.device_dttm;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.userz_id = null;
        this.device_lid = null;
        this.device_dttm = moment( '0001-01-01 00:00:00' ).toDate();
    }
}
