import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtText from '../first/ftText';
import FtButton from '../first/ftButton';
import FtSpacer from '../first/ftSpacer';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import VotingPosition  from '../models/votingPosition';
import VotingRound     from '../models/votingRound';
import VotingCandidate from '../models/votingCandidate';
import CandidateStatus from '../models/candidateStatus';

//********************************************************************
// PageVoteApprovalSecondary
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteApprovalSecondary( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup           , setPopup           ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy            , setBusy            ] = useState( false );
    const [ mdlPosition     , setMdlPosition     ] = useState<VotingPosition|null>( null );
    const [ mdlRound        , setMdlRound        ] = useState<VotingRound|null>( null );
    const [ lstCandidate    , setLstCandidate    ] = useState<VotingCandidate[]>( [] );
    const [ totalDelegates  , setTotalDelegates  ] = useState<number>( 0 );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( mdlPosition, mdlRound ) ) return ftBusy( { popup: popup } );

    /*
    PREMISE:
    this function approves or rejects votes on existing candidates
    approving hides buttons
    any updates reloads whole screen

    DESIGN:
    === Current Status ===
    Open Position : None/<name>
    Open Round : None/<name>
    ======================

    === Approve Candidates (Secondary) ===
     CANDIDATE       VOTES          ACTIONS
     <name>          <votes>        [Approve] [Reject]
    =============================
    */

    const txtStatus: any = {
        ss: '12',
        txtDescr: '20,WO#273891',
    };
    const txtHeading: any = {
        txtDescr: '20,WO#005493'
    };
    const txtItem: any = {
        txtDescr: '18,WO#000000'
    };
    const btnProps: any = {
        clr     : "#008000",
        clrX    : "#cccccc",
        txtClr  : "#ffffff",
        txtClrX : "#000000",
        pad: 3
    };
    const roundName = mdlRound?.round_no == 0 ? 'Nomination' : `${mdlRound?.round_no}`;
    const totalVotes: number = lstCandidate[0].total_count ?? 0;

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Current Voting Status' {...Th.frame}>
            <FtText {...txtStatus} text={`Open Position: ${mdlPosition?.name}`} />
            <FtText {...txtStatus} text={`Open Round : ${roundName}`} />
            <FtButton label="Refresh" {...btnProps} onTap={loadData} />
        </FtFrame>

        <FtFrame text='Approve Candidate Votes' {...Th.frame} md='rw'>
            <FtText ss='6' {...txtHeading} text='Candidate' />
            <FtText ss='2' {...txtHeading} text='Votes' />
            <FtText ss='4' {...txtHeading} text='Actions' />

            {lstCandidate.map( can => (<>
                <FtText ss='6' {...txtItem} text={can.name} />
                <FtText ss='2' {...txtItem} text={`${can.vote_count} (${can.vote_perc?.toFixed(2)} %)`} />
                <FtCon key={Ft.key()} ss='4' md='r' show={showButtons( can )}>
                    <FtButton key={Ft.key()} label="Approve" {...btnProps} onTap={() => candidateApproval( can, true )} />
                    <FtButton key={Ft.key()} label="Reject"  {...btnProps} onTap={() => candidateApproval( can, false )}  />
                </FtCon>
                <FtText ss='4' {...txtItem} text='Approved' show={showApproved( can )} />
                <FtText ss='4' {...txtItem} text='Rejected or not approved by Primary yet' show={showRejected( can )} />
                <FtSpacer h={10} show={!showButtons( can )} />
            </>))}
            <FtSpacer />
            <FtText ss='6:6' {...txtHeading} text={`Total Votes : ${totalVotes}`} />
            <FtText ss='6:6' {...txtHeading} text={`Total Delegates : ${totalDelegates} (Today only)`} />
            
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        loadData();
    }

    function loadData()
    {
        setBusy( true );
        FtRapi.callList( 'VoteApprovalInfo', {
            success: ( result, data ) => {
                setMdlPosition( data[0] );
                setMdlRound( data[1] );
                setLstCandidate( data[2] );
                setTotalDelegates( data[3] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function showButtons( can: VotingCandidate ): boolean
    {
        return can.status_id == CandidateStatus.CS_APPROVED_PRIMARY;
    }

    function showApproved( can: VotingCandidate ): boolean
    {
        return can.status_id == CandidateStatus.CS_APPROVED_SECONDARY;
    }

    function showRejected( can: VotingCandidate ): boolean
    {
        return can.status_id == CandidateStatus.CS_OPEN_FOR_VOTING;
    }    

    function candidateApproval( can: VotingCandidate, approved: boolean ): void
    {
        const msg: string = `Are you sure you want to ${approved ? 'approve' : 'reject'} this candidate's votes?`;
        ftPopupConfirm( setPopup, "Confirmation", msg, { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteSecondaryApproval', can.id, {
                urlParms: { approved: approved ? 1 : 0 },
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", result.message, { onClose: loadData } ),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData } ),
                complete: () => setBusy( false ),
            });
        } } );
    }
}
