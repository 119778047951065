import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtForm from '../first/ftForm';
import FtText from '../first/ftText';
import FtFmSubmit from '../first/ftFmSubmit';
import FtFmSearchField from '../first/ftFmSearchField';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import Th from '../general/th';

import GenLookup       from '../models/genLookup';
import VotingCandidate from '../models/votingCandidate';
import CandidateStatus from '../models/candidateStatus';

//********************************************************************
// PageVoteDisplayResults
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteDisplayResults( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup        , setPopup        ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy         , setBusy         ] = useState( false );
    const [ lstCandidate , setLstCandidate ] = useState<VotingCandidate[]>( [] );
    const [ lkpPosRound  , setLkpPosRound  ] = useState<GenLookup[]>( [] );
    const [ mdlCandidate , setMdlCandidate ] = useState<VotingCandidate>( new VotingCandidate() );

    //- hooks: effect/startup -----
    useEffect( onLoad, [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpPosRound ) ) return ftBusy( { popup: popup } );

    const headProps: any = {
        txtDescr: '36,WCO#273891',
    };
    const infoProps: any = {
        txtDescr: '30,WCO#000000',
    };

    //- render -----
    return (<FtCanvas {...Th.page}>
        {popup}

        <FtFrame text='Search Parameters' {...Th.frame}>
            <FtForm {...Th.form}
                models={{ m: mdlCandidate }}
                onSubmit={onSearch}
            >
                <FtFmSearchField name='m.round_id'  label='Position : Round' {...Th.field} ss='9' data={lkpPosRound} />
                <FtSpacer />
                <FtFmSubmit {...Th.submit} label='Search' />
                <FtSpacer />
            </FtForm>
        </FtFrame>

        <FtFrame text='Search Results' {...Th.frame}>
            <FtCon ss='12' md='rw'>
                <FtText ss='6' {...headProps} text='Name' />
                <FtText ss='1' {...headProps} text='Votes' />
                <FtText ss='1' {...headProps} text='Vote %' />
                <FtText ss='2' {...headProps} text='Finalist' />
                <FtText ss='2' {...headProps} text='Disqualified' />
            </FtCon>

            {lstCandidate.map( can => (<>
                <FtCon key={Ft.key()} ss='12' md='rw'>
                    <FtText key={Ft.key()} ss='6' {...infoProps} text={can.name} />
                    <FtText key={Ft.key()} ss='1' {...infoProps} text={can.vote_count} />
                    <FtText key={Ft.key()} ss='1' {...infoProps} text={can.vote_perc?.toFixed(2) ?? 0} />
                    <FtText key={Ft.key()} ss='2' {...infoProps} text={can.is_finalist == 1 ? 'Yes' : 'No'} />
                    <FtText key={Ft.key()} ss='2' {...infoProps} text={can.status_id == CandidateStatus.CS_DISQUALIFIED ? 'Yes' : 'No'} />
                </FtCon>
            </>))}
        </FtFrame>

    </FtCanvas>);

    //====================================================================
    // event handlers

    function onLoad(): void
    {
        loadData();
    }

    function loadData(): void
    {
        setBusy( true );
        FtRapi.callList( 'VotePosRoundLookup', {
            success: (result, data) => setLkpPosRound( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        });
    }

    function onSearch(): void
    {
        setBusy( true );
        FtRapi.callList( 'VotePosRoundSearch', {
            urlParms: { round_id : mdlCandidate.round_id },
            success: ( result, data ) => setLstCandidate( data[0] ),
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message ),
            complete: () => setBusy( false ),
        });
    }
}
