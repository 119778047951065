import PositionStatusBase from './base/positionStatusBase';

//********************************************************************
// PositionStatus class
// @FTGEN_PROGRESS: busy
export default class PositionStatus extends PositionStatusBase
{
    //====================================================================
    // static data members

    // #FTGEN_CONSTS_A#
    static PS_TO_BE_DONE = 1;
    static PS_OPEN = 2;
    static PS_COMPLETED = 3;
    // #FTGEN_CONSTS_Z#

    //====================================================================
    // init
    
    constructor()
    {
        super();
    }
}
