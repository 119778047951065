import Decimal from 'decimal.js';
import moment from 'moment';
import Ft from '../../first/ft';
import { FtModel } from '../../first/ftTypes';
import FtRapi from '../../first/ftRapi';

//********************************************************************
// VotingCandidateBase class : Generated : DO NOT MODIFY
export default class VotingCandidateBase implements FtModel 
{
    //====================================================================
    // properties
    id: number;
    round_id: number|null;
    position_id: number|null;
    attendee_id: number|null;
    name: string|null;
    surname: string|null;
    assembly_name: string|null;
    region_name: string|null;
    vote_count: number|null;
    total_count: number|null;
    vote_perc: Decimal|null;
    is_finalist: number|null;
    calc_dttm: Date|null;
    calc_user_id: number|null;
    status_id: number|null;
    status_capture_dttm: Date|null;
    status_capture_user_id: number|null;
    status_approve_primary_dttm: Date|null;
    status_approve_primary_user_id: number|null;
    status_approve_second_dttm: Date|null;
    status_approve_second_user_id: number|null;
    status_final_disq_dttm: Date|null;
    status_final_disq_user_id: number|null;
    disq_reason: string|null;

    //====================================================================
    // init

    constructor()
    {
        this.id = 0;
        this.round_id = 0;
        this.position_id = 0;
        this.attendee_id = 0;
        this.name = '';
        this.surname = '';
        this.assembly_name = '';
        this.region_name = '';
        this.vote_count = 0;
        this.total_count = 0;
        this.vote_perc = new Decimal('0.0');
        this.is_finalist = 0;
        this.calc_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.calc_user_id = 0;
        this.status_id = 0;
        this.status_capture_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_capture_user_id = 0;
        this.status_approve_primary_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_approve_primary_user_id = 0;
        this.status_approve_second_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_approve_second_user_id = 0;
        this.status_final_disq_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_final_disq_user_id = 0;
        this.disq_reason = '';
    }

    //====================================================================
    // instance api

    /// decode relevant members of obj to this members
    decodeFromObj( obj: any ): any
    {
        this.id = obj.id;
        this.round_id = obj.round_id;
        this.position_id = obj.position_id;
        this.attendee_id = obj.attendee_id;
        this.name = obj.name;
        this.surname = obj.surname;
        this.assembly_name = obj.assembly_name;
        this.region_name = obj.region_name;
        this.vote_count = obj.vote_count;
        this.total_count = obj.total_count;
        this.vote_perc = FtRapi.decodeDec( obj.vote_perc );
        this.is_finalist = obj.is_finalist;
        this.calc_dttm = FtRapi.decodeDttm( obj.calc_dttm );
        this.calc_user_id = obj.calc_user_id;
        this.status_id = obj.status_id;
        this.status_capture_dttm = FtRapi.decodeDttm( obj.status_capture_dttm );
        this.status_capture_user_id = obj.status_capture_user_id;
        this.status_approve_primary_dttm = FtRapi.decodeDttm( obj.status_approve_primary_dttm );
        this.status_approve_primary_user_id = obj.status_approve_primary_user_id;
        this.status_approve_second_dttm = FtRapi.decodeDttm( obj.status_approve_second_dttm );
        this.status_approve_second_user_id = obj.status_approve_second_user_id;
        this.status_final_disq_dttm = FtRapi.decodeDttm( obj.status_final_disq_dttm );
        this.status_final_disq_user_id = obj.status_final_disq_user_id;
        this.disq_reason = obj.disq_reason;
        return this;
    }

    /// encode relevant members of this to obj
    encodeToObj(): any
    {
        const map: any = {
            id : this.id,
            round_id : this.round_id,
            position_id : this.position_id,
            attendee_id : this.attendee_id,
            name : FtRapi.encodeStr( this.name ),
            surname : FtRapi.encodeStr( this.surname ),
            assembly_name : FtRapi.encodeStr( this.assembly_name ),
            region_name : FtRapi.encodeStr( this.region_name ),
            vote_count : this.vote_count,
            total_count : this.total_count,
            vote_perc : FtRapi.encodeDec( this.vote_perc ),
            is_finalist : this.is_finalist,
            calc_dttm : FtRapi.encodeDttm( this.calc_dttm ),
            calc_user_id : this.calc_user_id,
            status_id : this.status_id,
            status_capture_dttm : FtRapi.encodeDttm( this.status_capture_dttm ),
            status_capture_user_id : this.status_capture_user_id,
            status_approve_primary_dttm : FtRapi.encodeDttm( this.status_approve_primary_dttm ),
            status_approve_primary_user_id : this.status_approve_primary_user_id,
            status_approve_second_dttm : FtRapi.encodeDttm( this.status_approve_second_dttm ),
            status_approve_second_user_id : this.status_approve_second_user_id,
            status_final_disq_dttm : FtRapi.encodeDttm( this.status_final_disq_dttm ),
            status_final_disq_user_id : this.status_final_disq_user_id,
            disq_reason : FtRapi.encodeStr( this.disq_reason ),
        };
        return map;
    }

    /// copy members from src object of same type
    copyFrom( src: any ): any
    {
        this.id = Ft.parseInt0( src.id );
        this.round_id = Ft.parseInt0Null( src.round_id );
        this.position_id = Ft.parseInt0Null( src.position_id );
        this.attendee_id = Ft.parseInt0Null( src.attendee_id );
        this.name = src.name;
        this.surname = src.surname;
        this.assembly_name = src.assembly_name;
        this.region_name = src.region_name;
        this.vote_count = Ft.parseInt0Null( src.vote_count );
        this.total_count = Ft.parseInt0Null( src.total_count );
        this.vote_perc = new Decimal( src.vote_perc );
        this.is_finalist = Ft.parseInt0Null( src.is_finalist );
        this.calc_dttm = src.calc_dttm;
        this.calc_user_id = Ft.parseInt0Null( src.calc_user_id );
        this.status_id = Ft.parseInt0Null( src.status_id );
        this.status_capture_dttm = src.status_capture_dttm;
        this.status_capture_user_id = Ft.parseInt0Null( src.status_capture_user_id );
        this.status_approve_primary_dttm = src.status_approve_primary_dttm;
        this.status_approve_primary_user_id = Ft.parseInt0Null( src.status_approve_primary_user_id );
        this.status_approve_second_dttm = src.status_approve_second_dttm;
        this.status_approve_second_user_id = Ft.parseInt0Null( src.status_approve_second_user_id );
        this.status_final_disq_dttm = src.status_final_disq_dttm;
        this.status_final_disq_user_id = Ft.parseInt0Null( src.status_final_disq_user_id );
        this.disq_reason = src.disq_reason;
        return this;
    }

    /// clear all members to default values
    clear(): void
    {
        this.id = 0;
        this.round_id = null;
        this.position_id = null;
        this.attendee_id = null;
        this.name = null;
        this.surname = null;
        this.assembly_name = null;
        this.region_name = null;
        this.vote_count = null;
        this.total_count = null;
        this.vote_perc = null;
        this.is_finalist = null;
        this.calc_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.calc_user_id = null;
        this.status_id = null;
        this.status_capture_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_capture_user_id = null;
        this.status_approve_primary_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_approve_primary_user_id = null;
        this.status_approve_second_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_approve_second_user_id = null;
        this.status_final_disq_dttm = moment( '0001-01-01 00:00:00' ).toDate();
        this.status_final_disq_user_id = null;
        this.disq_reason = null;
    }
}
