import React, { useState, useEffect } from 'react';
import type { FtPageProps } from '../first/ftTypes';
import Ft from '../first/ft';
import FtRapi from '../first/ftRapi';
import FtFrame from '../first/ftFrame';
import FtCanvas from '../first/ftCanvas';
import FtCon from '../first/ftCon';
import FtSpacer from '../first/ftSpacer';
import FtText from '../first/ftText';
import FtButton from '../first/ftButton';
import { ftBusy } from '../first/ftBusy';
import { ftPopupMessage } from '../first/ftPopupMessage';
import { ftPopupConfirm } from '../first/ftPopupOptions';
import Th from '../general/th';

import GenLookup      from '../models/genLookup';
import VotingPosition from '../models/votingPosition';
import VotingRound    from '../models/votingRound';
import PositionStatus from '../models/positionStatus';
import RoundStatus    from '../models/roundStatus';

//********************************************************************
// PageVoteProcessManager
// @FTGEN_PROGRESS: done
// ...todo: specify props here...
export default function PageVoteProcessManager( { navigation, options }: FtPageProps ): JSX.Element
{
    //====================================================================
    // hooks: no conditions here

    //- hooks: state -----
    const nav = Ft.useNav( navigation );
    const [ popup              , setPopup              ] = useState( <></> ); // placeholder for any popup message/options
    const [ busy               , setBusy               ] = useState( false );
    const [ lkpPositionStatus  , setLkpPositionStatus  ] = useState<GenLookup[]|null>( null );
    const [ lkpRoundStatus     , setLkpRoundStatus     ] = useState<GenLookup[]|null>( null );
    const [ lkpCandidateStatus , setLkpCandidateStatus ] = useState<GenLookup[]|null>( null );
    const [ refPositionStatus  , setRefPositionStatus  ] = useState<any[]|null>( null );
    const [ refRoundStatus     , setRefRoundStatus     ] = useState<any[]|null>( null );
    const [ lstPosition        , setLstPosition        ] = useState<VotingPosition[]|null>( null );
    const [ lstRound           , setLstRound           ] = useState<VotingRound[]|null>( null );

    //- hooks: effect/startup -----
    useEffect( () => loadData(), [] );

    //====================================================================
    // render

    if ( busy || Ft.eAny( lkpPositionStatus, lkpRoundStatus, lkpCandidateStatus, refPositionStatus, refRoundStatus, lstPosition, lstRound ) ) return ftBusy( { popup: popup } );

    /*
    DESIGN:

    === Positions ===
    <position> | <status> | [Open] | [Close] | [Reverse]
    ...
    =================

    === Rounds ===
    <position> | <round-name> | <status> | [Start Processing] | [Close] | [Reverse]
    ...
    [Open Next Round]
    ==============
    */

    const txtHeading: any = {
        txtDescr: '20,WCO#005493'
    };
    const txtItem: any = {
        txtDescr: '18,WCO#000000'
    };
    const txtPosition: any = {
        txtDescr: '22,WCO#2196f3'
    };
    const btnPropsGr: any = {
        clr     : "#008000",
        clrX    : "#cccccc",
        txtClr  : "#ffffff",
        txtClrX : "#000000",
        pad: 3
    };
    const btnPropsOr: any = {
        clr     : "#e67e22",
        clrX    : "#cccccc",
        txtClr  : "#ffffff",
        txtClrX : "#000000",
        pad: 3
    };    

    return (<FtCanvas {...Th.page}>
        {popup}

        {/* === Positions box =====================================================================*/}
        <FtFrame text='Positions' {...Th.frame}>

            <FtCon ss='12' md='rw'>
                <FtText ss='3' {...txtHeading} text='Position' />
                <FtText ss='3' {...txtHeading} text='Status' />
                <FtText ss='6' {...txtHeading} text='Actions' />
            </FtCon>
            <FtSpacer h={5} />

            {lstPosition!.map( pos => (
                <FtCon key={Ft.key()} ss='12' md='rw'>
                    <FtText key={Ft.key()} ss='3' {...txtItem} align='C' text={pos.name} />
                    <FtText key={Ft.key()} ss='3' {...txtItem} align='C' text={refPositionStatus![ pos.status_id! ]} />
                    <FtCon key={Ft.key()} ss='6' md='r'>
                        <FtButton key={Ft.key()} label="Open"            busy={busy} {...btnPropsGr} en={posCanOpen( pos )}    onTap={() => posOpen( pos )}   />
                        <FtButton key={Ft.key()} label="Close Position"  busy={busy} {...btnPropsGr} en={posCanClose( pos )}   onTap={() => posClose( pos )}    />
                        <FtButton key={Ft.key()} label="Reverse"         busy={busy} {...btnPropsOr} en={posCanReverse( pos )} onTap={() => posReverse( pos )}  />
                    </FtCon>
                </FtCon>
            ))}
        </FtFrame>

        {/* === Rounds box =====================================================================*/}
        <FtFrame text='Rounds' {...Th.frame}>

            <FtCon ss='12' md='rw'>
                <FtText ss='3' {...txtHeading} text='Round' />
                <FtText ss='3' {...txtHeading} text='Status' />
                <FtText ss='6' {...txtHeading} text='Actions' />
            </FtCon>
            <FtSpacer h={5} />

            {lstPosition!.map( pos => (<>
                <FtText key={Ft.key()} ss='12' {...txtPosition} text={pos.name} />
                {Ft.lq( lstRound! ).where( x => x.position_id == pos.id ).toArray().map( ( rnd, idx, lst ) => (
                    <FtCon key={Ft.key()} ss='12' md='rw'>
                        <FtText key={Ft.key()} ss='3' {...txtItem} align='C' text={rnd.round_name} />
                        <FtText key={Ft.key()} ss='3' {...txtItem} align='C' text={roundStatus( rnd )} />
                        <FtCon key={Ft.key()} ss='6' md='r'>
                            <FtButton key={Ft.key()} label="Start Processing"           busy={busy} {...btnPropsGr} en={roundCanProcess( rnd )}       onTap={() => roundProcess( rnd )}  />
                            <FtButton key={Ft.key()} label="Close and Display Results"  busy={busy} {...btnPropsGr} en={roundCanClose( rnd )}         onTap={() => roundClose( rnd )}    />
                            <FtButton key={Ft.key()} label="Reverse"                    busy={busy} {...btnPropsOr} en={roundCanReverse( rnd, lst )}  onTap={() => roundReverse( rnd )}  />
                        </FtCon>
                    </FtCon>
                ))}
                <FtCon key={Ft.key()} ss='2:10' md='rw' show={roundCanOpen( pos )}>
                    <FtButton label={openRoundBtnText( pos )} busy={busy} {...btnPropsGr} onTap={() => roundOpen( pos )} />
                </FtCon>
                <FtCon ss='12' h={10} />
                <FtCon ss='12' h={3} bkgClr="#a0a0a0" />
                <FtCon ss='12' h={10} />
            </>))}
             <FtSpacer />

        </FtFrame>
        <FtSpacer />

    </FtCanvas>);

    //====================================================================
    // event handlers

    function loadData(): void
    {
        setBusy( true );
        FtRapi.callRead( 'VoteBasicsRead', 0, {
            success: ( result, data ) => {
                setLkpPositionStatus( data[0] );
                setRefPositionStatus( Ft.objArrToIdVal( data[0] ) );
                setLkpRoundStatus( data[1] );
                setRefRoundStatus( Ft.objArrToIdVal( data[1] ) );
                setLkpCandidateStatus( data[2] );
                setLstPosition( data[3] );
                setLstRound( data[4] );
            },
            error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: nav.pop } ),
            complete: () => setBusy( false ),
        } );
    }

    function posCanOpen( pos: VotingPosition ): boolean
    {
        if ( pos.status_id != PositionStatus.PS_TO_BE_DONE ) return false;
        if ( Ft.lq( lstPosition! ).any( x => x.id != pos.id && x.status_id == PositionStatus.PS_OPEN ) ) return false; // bail if any other position is open
        return true;
    }

    function posCanClose( pos: VotingPosition ): boolean
    {
        if ( pos.status_id != PositionStatus.PS_OPEN ) return false; // status == open
        if ( Ft.lq( lstRound! ).any( x => x.position_id == pos.id && x.status_id != RoundStatus.RS_COMPLETED ) ) return false; //all rounds status == completed
        return true;
    }

    function posCanReverse( pos: VotingPosition ): boolean
    {
        return pos.status_id != PositionStatus.PS_TO_BE_DONE;  // any position can be reversed if not status is to-be-done
    }

    function posOpen( pos: VotingPosition ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to open this position?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VotePositionOpen', pos!.id, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Position opened successfully", { btn: "Close", onClose: loadData }),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function posClose( pos: VotingPosition ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to close this position?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VotePositionClose', 0, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Position closed successfully", { btn: "Close", onClose: loadData }),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                complete: () => setBusy( false ),
            });
        } } );
    }

    function posReverse( pos: VotingPosition ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", 
            "Are you sure you want to reverse this position, this cannot be undone and all votes will be permanently deleted?", 
            { onA: () => {
                setBusy( true );
                FtRapi.callUpdate( 'VotePositionReverse', pos.id, {
                    success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Position reversed successfully", { btn: "Close", onClose: loadData }),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                    complete: () => setBusy( false ),
                });
            }
        });
    }

    function roundStatus( rnd: VotingRound ): string
    {
        return rnd.status_id != RoundStatus.RS_COMPLETED
            ? refRoundStatus![ rnd.status_id! ]
            : "Completed and displaying results";
    }

    function roundCanOpen( pos: VotingPosition ): boolean
    {
        if ( pos.status_id != PositionStatus.PS_OPEN ) return false;   // pos status == open
        const lastRound = Ft.lq( lstRound! ).where( x => x.position_id == pos.id ).orderBy( x => x.id ).last();
        if ( lastRound.status_id == RoundStatus.RS_CREATED || lastRound.status_id == RoundStatus.RS_COMPLETED ) return true; // last round == created || == completed
        return false;
    }

    function openRoundBtnText( pos: VotingPosition ): string
    {
        if ( !roundCanOpen( pos ) ) return '';
        const lastRound = Ft.lq( lstRound! ).where( x => x.position_id == pos.id ).orderBy( x => x.id ).last();
        return lastRound.round_no == 0 && lastRound.status_id == RoundStatus.RS_CREATED
            ? "Open Round for Nominations"
            : "Open Next Round for Voting";
    }

    function roundCanProcess( rnd: VotingRound ): boolean
    {
        return rnd.status_id == RoundStatus.RS_OPEN_FOR_VOTING;
    }

    function roundCanClose( rnd: VotingRound ): boolean
    {
        return rnd.status_id == RoundStatus.RS_APPROVED_FINAL;
    }

    function roundCanReverse( rnd: VotingRound, lst: VotingRound[] ): boolean
    {
        return rnd.status_id != RoundStatus.RS_CREATED && rnd.id == lst?.findLast( x => true )?.id;
    }

    function roundOpen( pos: VotingPosition ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to open this round?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteRoundOpen', 0, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Round opened successfully", { btn: "Close", onClose: loadData }),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                complete: () => setBusy( false ),
            });
        } });
    }

    function roundProcess( rnd: VotingRound ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to start processing on this round?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteRoundProcess', 0, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Round switched to 'Processing' successfully", { btn: "Close", onClose: loadData }),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                complete: () => setBusy( false ),
            });
        }});
    }

    function roundClose( rnd: VotingRound ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", "Are you sure you want to close this round and display its results?", { onA: () => {
            setBusy( true );
            FtRapi.callUpdate( 'VoteRoundClose', 0, {
                success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Round closed successfully", { btn: "Close", onClose: loadData }),
                error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                complete: () => setBusy( false ),
            });
        }});
    }

    function roundReverse( rnd: VotingRound ): void
    {
        ftPopupConfirm( setPopup, "Confirmation", 
            "Are you sure you want to reverse this round, all round votes will be deleted permanently and cannot be undone?", 
            { onA: () => {
                setBusy( true );
                FtRapi.callUpdate( 'VoteRoundReverse', 0, {
                    success: ( result, data ) => ftPopupMessage( setPopup, "Success", "Round reversed successfully", { btn: "Close", onClose: loadData }),
                    error: ( { message } ) => ftPopupMessage( setPopup, "Error", message, { onClose: loadData }),
                    complete: () => setBusy( false ),
                });
            }    
        });
    }
}
